import { Box, Button, FormControl, MenuItem, Select, SelectChangeEvent, styled, Typography } from "@mui/material"
import { GridColDef, GridSortDirection } from "@mui/x-data-grid";
import React, { useCallback, useEffect } from "react";
import { useMemo } from "react"
import { Link } from "react-router-dom";
import { usePermissionData, useUpdateMemberRole } from "src/api/hooks/useOrganization.hooks";
import { debounce } from "src/utils/debounce";
import { useDebounce } from 'use-debounce'

export interface IPermissionTableItem {
    name: string,
    googleId: string,
    role: ROLE_ENUM,
    id: string,
}

export enum ROLE_ENUM {
    ADMIN = 'admin',
    OWNER = 'owner',
    USER = 'user'
}

type SORT_TYPE = "name"

export type ROLE_FILTER = "admin" | 'user' | ''

const usePermissionTable = () => {

    const [take, setTake] = React.useState<number>(5)
    const [skip, setSkip] = React.useState<number>(1)
    const [count, setCount] = React.useState<number | undefined>(1)
    const [page, setPage] = React.useState<number>(1)
    const [inputState, setInputState] = React.useState<string>('')
    const [searchParam, setSearchParam] = React.useState<string>('')
    const [sortType, setSortType] = React.useState<SORT_TYPE>("name")
    const [sortOrder, setSortOrder] = React.useState<GridSortDirection>("asc")
    const [filterByRole, setRoleFilter] = React.useState<ROLE_FILTER | undefined>(undefined)

    const [debouncedSearch] = useDebounce(inputState, 1000)

    const groupData = usePermissionData(
        {
            limit: take,
            page: skip,
            search: debouncedSearch || "",
            role: filterByRole,
            sortBy: sortType,
            sortOrder: sortOrder
        },
        setCount
    )

    const handlePresSortIcon = (sortType: string) => {
        setSortType(prev => {
            if (prev === sortType) {
                setSortOrder(prev => prev === 'asc' ? "desc" : 'asc')
            } else {
                setSortOrder('asc')
            }
            return sortType as SORT_TYPE
        })
    }

    const userManagementTableData = useMemo(() => {
        let rows: IPermissionTableItem[] = []
        let columns: GridColDef[] = [
            { field: 'name', headerName: 'Member', width: 150, sortable: true },
            { field: 'googleId', headerName: 'Google ID', width: 200, maxWidth: 680 },
            { field: 'role', headerName: 'Role', width: 100, maxWidth: 132 },
        ]


        if (groupData && groupData.data) {
            rows = groupData.data.users.map(({ _id, name, googleID, role, orgId }) => {
                return {
                    name: name,
                    googleId: googleID,
                    role: role as ROLE_ENUM,
                    id: orgId,
                }
            })
        }

        return { rows, columns }
    }, [groupData])


    return {
        userManagementTableData,
        skip,
        take,
        count,
        sortType,
        sortOrder,
        inputState,
        setSkip,
        setCount,
        setTake,
        setInputState,
        setRoleFilter,
        isLoading: groupData.isFetching,
        handlePresSortIcon,
    }
}

export default usePermissionTable
