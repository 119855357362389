import React from 'react'

const TimerIcon = ({ color = '#FFFFFF' }: { color?: string }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1577_2187)">
                <path opacity="0.3" d="M12.0699 6.00977C8.19995 6.00977 5.06995 9.13977 5.06995 13.0098C5.06995 16.8798 8.19995 20.0098 12.0699 20.0098C15.9399 20.0098 19.0699 16.8798 19.0699 13.0098C19.0699 9.13977 15.9399 6.00977 12.0699 6.00977ZM13.0699 14.0098H11.0699V8.00977H13.0699V14.0098Z" fill={color} />
                <path d="M9.06995 1.00977H15.0699V3.00977H9.06995V1.00977ZM11.0699 8.00977H13.0699V14.0098H11.0699V8.00977ZM19.0999 7.38977L20.5199 5.96977C20.0899 5.45977 19.6199 4.97977 19.1099 4.55977L17.6899 5.97977C16.1399 4.73977 14.1899 3.99977 12.0699 3.99977C7.09995 3.99977 3.06995 8.02977 3.06995 12.9998C3.06995 17.9698 7.08995 21.9998 12.0699 21.9998C17.0499 21.9998 21.0699 17.9698 21.0699 12.9998C21.0699 10.8898 20.3299 8.92977 19.0999 7.38977ZM12.0699 20.0098C8.19995 20.0098 5.06995 16.8798 5.06995 13.0098C5.06995 9.13977 8.19995 6.00977 12.0699 6.00977C15.9399 6.00977 19.0699 9.13977 19.0699 13.0098C19.0699 16.8798 15.9399 20.0098 12.0699 20.0098Z" fill={color} />
            </g>
            <defs>
                <clipPath id="clip0_1577_2187">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default TimerIcon