import { Box, Button, FormControl, MenuItem, Select, SelectChangeEvent, styled, Typography } from "@mui/material"
import { GridSortDirection, GridColDef } from "@mui/x-data-grid";
import React, { useCallback, useEffect } from "react";
import { useMemo } from "react"
import { useExcludeOrgMember, useIncludeOrgMember, useUsersData } from "src/api/hooks/userManagement.hooks";
import { debounce } from "src/utils/debounce";
import { useDebounce } from "use-debounce";

export interface IGroupMemberItem {
    name: string,
    googleId: string,
    status: number,
    id: string,
}

type SORT_TYPE = "name"

const useGroupManagementTable = ({ groupId }: { groupId: string }) => {

    const [take, setTake] = React.useState<number>(5)
    const [skip, setSkip] = React.useState<number>(0)
    const [count, setCount] = React.useState<number | undefined>(1)
    const [page, setPage] = React.useState<number>(1)
    const [inputState, setInputState] = React.useState<string>('')
    const [searchParam, setSearchParam] = React.useState<string>('')
    const [sortType, setSortType] = React.useState<SORT_TYPE>("name")
    const [sortOrder, setSortOrder] = React.useState<GridSortDirection>("asc")

    const [debouncedSearch] = useDebounce(inputState, 1000)

    const userData = useUsersData(groupId,
        {
            limit: take,
            page: skip,
            search: debouncedSearch || "",
            sort: sortType,
            order: sortOrder,
        },
        setCount
    )

    const handlePresSortIcon = (sortType: string) => {
        setSortType(prev => {
            if (prev === sortType) {
                setSortOrder(prev => prev === 'asc' ? "desc" : 'asc')
            } else {
                setSortOrder('asc')
            }
            return sortType as SORT_TYPE
        })
    }

    const groupManagementTableData = useMemo(() => {

        let rows: IGroupMemberItem[] = []
        let columns: GridColDef[] = [
            { field: 'name', headerName: 'Student', width: 230, sortable: true },
            { field: 'googleId', headerName: 'Google ID', width: 690 },
            { field: 'status', headerName: 'Status', width: 230 },
        ]

        if (userData && userData.data) {
            rows = userData.data.members.map(({ _id, name, googleID, excluded }) => {
                return {
                    name: name.fullName,
                    googleId: googleID,
                    status: Number(excluded),
                    id: _id,
                }
            })
        }

        return { rows, columns }
    }, [userData])


    return {
        groupManagementTableData,
        skip,
        take,
        count,
        sortType,
        sortOrder,
        inputState,
        setInputState,
        setSkip,
        setCount,
        setTake,
        isLoading: userData.isFetching,
        handlePresSortIcon,
    }
}

export default useGroupManagementTable
