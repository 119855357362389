import { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  useTheme,
  CardActionArea,
  Typography,
  MenuList,
  Grid,
  Divider,
  Card,
  MenuItem,
  ListItemText,
  alpha,
  Link,
  Popover,
  Stack,
  styled,
  IconButton,
  CardHeader,
  Popper,
  ClickAwayListener
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownTwoToneIcon from '@mui/icons-material/KeyboardArrowDownTwoTone';
import ChevronRightTwoToneIcon from '@mui/icons-material/ChevronRightTwoTone';
import Text from 'src/components/Text';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import PeopleOutlineTwoToneIcon from '@mui/icons-material/PeopleOutlineTwoTone';
import DashboardCustomizeTwoToneIcon from '@mui/icons-material/DashboardCustomizeTwoTone';
import ContactSupportTwoToneIcon from '@mui/icons-material/ContactSupportTwoTone';
import { CloseTwoTone, Settings } from '@mui/icons-material';
import TemplateCard from 'src/components/molecules/TemplateCard/TemplateCard';
import QuickSendCard from 'src/components/molecules/QuickSendCard/QuickSendCard';
import { useMutation, useQuery } from '@tanstack/react-query';
import presetsAPI from 'src/controllers/presets';
import useAuth from 'src/hooks/useAuth';
import { useNavigate } from 'react-router-dom';

const template = {
  "content": {
    "title": "New message received",
    "subtitle": "You have a new message from John Doe",
    "body": "Hello, you have a new message from John Doe"
  },
  "targets": {
    "members": [
      {
        "_id": "61700e73722cbe51f461d1c1",
        "googleID": "abc123",
        "groups": [
          {
            "id": "616ffd902d50f0ff292f2a19",
            "name": "Group A"
          },
          {
            "id": "616ffd902d50f0ff292f2a20",
            "name": "Group B"
          }
        ],
        "isAdmin": false,
        "name": {
          "givenName": "John",
          "familyName": "Doe",
          "fullName": "John Doe"
        },
        "organization": "61700e73722cbe51f461d1c0",
        "primaryEmail": "johndoe@example.com"
      }
    ],
    "groups": [
      {
        "_id": "616ffd902d50f0ff292f2a19",
        "organization": "61700e73722cbe51f461d1c0",
        "name": "Group A",
        "memberIDs": [
          "61700e73722cbe51f461d1c1",
          "61700e73722cbe51f461d1c2",
          "61700e73722cbe51f461d1c3"
        ],
        "domain": "example.com"
      },
      {
        "_id": "616ffd902d50f0ff292f2a20",
        "organization": "61700e73722cbe51f461d1c0",
        "name": "Group B",
        "memberIDs": [
          "61700e73722cbe51f461d1c4",
          "61700e73722cbe51f461d1c5",
          "61700e73722cbe51f461d1c6"
        ],
        "domain": "example.com"
      }
    ]
  },
  "notifStyle": "popup",
  "initiator": {
    "name": "John Doe",
    "email": "johndoe@example.com"
  },
  "name": "New Message",
  "lifetime": 3600,
  "stylingInfo": {
    "backgroundColor": "#000000",
    "icon": "https://example.com/icon.png"
  }
}

const LabelWrapper = styled(Box)(
  ({ theme }) => `
  font-size: ${theme.typography.pxToRem(10)};
  font-weight: bold;
  text-transform: uppercase;
  border-radius: ${theme.general.borderRadiusSm};
  padding: ${theme.spacing(0.5, 1, 0.4)};
`
);

const CardActionAreaWrapper = styled(CardActionArea)(
  ({ theme }) => `
      .MuiTouchRipple-root {
        opacity: .2;
      }

      .MuiCardActionArea-focusHighlight {
        background: ${theme.colors.primary.main};
      }

      &:hover {
        .MuiCardActionArea-focusHighlight {
          opacity: .05;
        }
      }
`
);

const MenuListWrapperSecondary = styled(MenuList)(
  ({ theme }) => `
  padding: ${theme.spacing(3)};

  & .MuiMenuItem-root {
      border-radius: 50px;
      padding: ${theme.spacing(1, 1, 1, 2.5)};
      min-width: 200px;
      margin-bottom: 2px;
      position: relative;
      color: ${theme.colors.alpha.black[70]};

      &.Mui-selected,
      &:hover,
      &.MuiButtonBase-root:active {
          background: ${theme.colors.alpha.black[10]};
          color: ${theme.colors.alpha.black[100]};
      }

      &:last-child {
          margin-bottom: 0;
      }
    }
`
);

const MenuListWrapperSuccess = styled(MenuList)(
  ({ theme }) => `
  padding: ${theme.spacing(3)};

  & .MuiMenuItem-root {
      border-radius: 50px;
      padding: ${theme.spacing(1, 1, 1, 2.5)};
      min-width: 200px;
      margin-bottom: 2px;
      position: relative;
      color: ${theme.colors.success.main};

      &.Mui-selected,
      &:hover,
      &.MuiButtonBase-root:active {
          background: ${theme.colors.success.lighter};
          color: ${theme.colors.success.dark};
      }

      &:last-child {
          margin-bottom: 0;
      }
    }
`
);

const MenuListWrapperError = styled(MenuList)(
  ({ theme }) => `
  padding: ${theme.spacing(3)};

  & .MuiMenuItem-root {
      border-radius: 50px;
      padding: ${theme.spacing(1, 1, 1, 2.5)};
      min-width: 200px;
      margin-bottom: 2px;
      position: relative;
      color: ${theme.colors.error.main};

      &.Mui-selected,
      &:hover,
      &.MuiButtonBase-root:active {
          background: ${theme.colors.error.lighter};
          color: ${theme.colors.error.dark};
      }

      &:last-child {
          margin-bottom: 0;
      }
    }
`
);

const DotLegend = styled('span')(
  ({ theme }) => `border-radius: 22px;
    width: ${theme.spacing(1.4)};
    height: ${theme.spacing(1.45)};
    display: inline-block;
    border: ${theme.colors.alpha.white[100]} solid 2px;
`
);


function HeaderMenu() {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const acc = useAuth();
  const navigate = useNavigate();

  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const ref2 = useRef<any>(null);
  const [isOpen2, setOpen2] = useState<boolean>(false);

  const handleOpen2 = (): void => {
    setOpen2(true);
  };

  const handleClose2 = (): void => {
    setOpen2(false);
  };

  const presetsQuery = useQuery({
    queryKey: ['presetsData'],
    queryFn: () => presetsAPI.listPresets(acc.user.domain)
      .then((res) => res.presets.map((obj) => ({
        ...obj.content,
        notifStyle: obj.style,
        name: obj.name,
        tags: obj.tags,
        _id: obj._id,
        stylingInfo: { icon: obj.icon, backgroundColor: obj.backgroundColor },
        createdAt: obj.createdAt,
        updatedAt: obj.updatedAt
      }))),
    placeholderData: []
  })

  const sendPresetMutation = useMutation({
    mutationFn: ({
      templateID,
    }: {
      templateID: any;
    }) => presetsAPI.sendPreset(acc.user.domain, templateID)
  });

  return (
    <ClickAwayListener onClickAway={handleClose2}>
      <Box>
        <Box
          sx={{
            display: { xs: 'none', md: 'inline-flex', }
          }}
        >
          <Button
            ref={ref2}
            onClick={handleOpen2}
            endIcon={<KeyboardArrowDownTwoToneIcon />}
            color="secondary"
            size="small"
            sx={{
              px: 2,
              backgroundColor: `${theme.colors.secondary.lighter}`,
              color: `${theme.colors.secondary.dark}`,

              '.MuiSvgIcon-root': {
                color: `${theme.colors.secondary.dark}`,
                transition: `${theme.transitions.create(['color'])}`
              },

              '&:hover': {
                backgroundColor: `${theme.colors.secondary.main}`,
                color: `${theme.palette.getContrastText(
                  theme.colors.secondary.main
                )}`,

                '.MuiSvgIcon-root': {
                  color: `${theme.palette.getContrastText(
                    theme.colors.secondary.main
                  )}`
                }
              }
            }}
          >
            {t('Quick Send')}
          </Button>
        </Box>
        <Popper
          anchorEl={ref2.current}
          open={isOpen2}
          disablePortal={false}
          placement={'bottom-start'}
          style={{
          }}
          sx={{
            backgroundColor: '#FFFFFF',
            minWidth: '306px',
            width: '306px',
            boxShadow: '0px 4px 28px 0px rgba(112, 112, 112, 0.25)',
            padding: '24px',
            zIndex: 99999,
            borderRadius: '8px',
            marginTop: '10px !important',
            '& .MuiList-root': {
              padding: '0',
            },
            '& .MuiMenuItem-root': {
              fontSize: '16px',
              lineHeight: '17px',
              color: '#355670',
              padding: '8px 10px',
              backgroundColor: '#FFFFFF',
              '&:hover': {
                backgroundColor: '#FFFFFF',
              },
            },
          }}
        >
          <Box>
            <Button sx={{
              bgcolor: '#FD3535',
              borderRadius: '50%',
              height: '36px',
              width: '36px',
              minWidth: '36px',
              position: 'absolute',
              top: '-20px',
              right: '-20px',
              '&:hover': {
                backgroundColor: '#FD3535'
              }
            }}
              onClick={handleClose2}
            >
              <img src='/static/images/icons/cross-icon.svg' alt='close' style={{ width: '18.67px', height: '18.67px' }} />
            </Button>
            <CardHeader
              sx={{ p: 0, display: 'flex', alignItems: 'center' }}
              title={
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h4">Quick send</Typography>
                  </div>
                  <div style={{ marginRight: '8px' }}>
                    <IconButton onClick={() => { navigate('/quick-send-settings'); handleClose2(); }} >
                      <Settings />
                    </IconButton>
                  </div>
                </div>
              }
              action={
                <div style={{ display: 'flex', alignItems: 'center' }} />
              }
            />
            <Divider sx={{mt:2}}/>
            <Grid container sx={{pt:3}} item display="flex" alignItems="center" direction="column">
              {presetsQuery.data.map((preset, index) => <QuickSendCard
                key={preset._id}
                details={{
                  title: preset.title,
                  subtitle: preset.subtitle,
                  body: preset.body,
                  name: preset.name,
                }}
                notifStyle={preset.notifStyle}
                id={preset._id}
                sendPresetMutation={sendPresetMutation}
              />)}
              {presetsQuery.data.length === 0 &&
                <>Go to your Quick Send settings to add quick send presets here.</>
              }
            </Grid>

          </Box>
        </Popper>
      </Box>
    </ClickAwayListener>
  );
}

export default HeaderMenu;
