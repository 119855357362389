import { useRef, useState } from 'react'
import useAuth from 'src/hooks/useAuth'
import { useNavigate } from 'react-router-dom'

import {
  Avatar,
  Box,
  Button,
  Divider,
  MenuList,
  alpha,
  IconButton,
  MenuItem,
  ListItemText,
  Popover,
  Typography,
  styled,
  useTheme,
  ButtonBase,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone'
import ChevronRightTwoToneIcon from '@mui/icons-material/ChevronRightTwoTone'
import Chart from 'react-apexcharts'
import type { ApexOptions } from 'apexcharts'
import Text from 'src/components/Text'
import MonetizationOnTwoToneIcon from '@mui/icons-material/MonetizationOnTwoTone'
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone'
import TimerIcon from 'src/components/SvgComponents/TimerIcon'
import { Link } from 'react-router-dom'

const DotLegend = styled('span')(
  ({ theme }) => `
    border-radius: 22px;
    width: ${theme.spacing(1.38)};
    height: ${theme.spacing(1.4)};
    display: inline-block;
    border: ${theme.colors.alpha.white[100]} solid 2px;
`
)

const StyledLink = styled(Link)(
  ({ theme }) => `
    width: 103px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #1A75FF;
    border-radius: 9px;
    text-decoration: none;
    color: #1A75FF;
    font-size: 14px;
    font-weight: 600;
    line-weight: 16.94px;
    text-align: center;
    vertical-align: center
`
)

const UserBoxButton = styled(ButtonBase)(
  ({ theme }) => `
  display: flex;
  gap: 5px;
  padding: 0;
  height: ${theme.spacing(4)};
  margin-left: ${theme.spacing(1)};
  border-radius: ${theme.general.borderRadiusLg};
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.94px;
  text-align: center;
  color: #000C57;
`
)

const UserAvatar = styled(Avatar)(
  ({ theme }) => `
        height: 44px;
        width: 44px;
        border-radius: ${theme.general.borderRadiusLg};
`
)

const MenuListWrapperPrimary = styled(MenuList)(
  ({ theme }) => `
  padding: ${theme.spacing(2)};

  & .MuiMenuItem-root {
      border-radius: 50px;
      padding: ${theme.spacing(1, 1, 1, 2.5)};
      min-width: 200px;
      margin-bottom: 2px;
      position: relative;
      color: ${theme.colors.alpha.black[100]};

      &.Mui-selected,
      &:hover,
      &.MuiButtonBase-root:active {
          background: ${theme.colors.primary.lighter};
          color: ${theme.colors.primary.main};
      }

      &:last-child {
          margin-bottom: 0;
      }
    }
`
)

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${alpha(theme.colors.alpha.black[100], 0.08)};
        padding: ${theme.spacing(2)};
`
)

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
)

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
)

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${theme.palette.secondary.light}
`
)

const transformName = (fullName: string): string => {
  const parts = fullName.trim().split(' ')
  if (parts.length < 2) {
    throw new Error(
      'Name must include at least a first name and a last name'
    )
  }
  const firstName = parts[0]
  const lastNameInitial = parts[parts.length - 1][0]
  return `${firstName} ${lastNameInitial}.`
}

function HeaderUserbox() {
  const { t }: { t: any } = useTranslation()
  const theme = useTheme()

  const navigate = useNavigate()

  const { user, logout } = useAuth()

  const ref = useRef<any>(null)
  const [isOpen, setOpen] = useState<boolean>(false)

  const isTrial = user.orgStatus === 'Trial'

  const handleOpen = (): void => {
    setOpen(true)
  }

  const handleClose = (): void => {
    setOpen(false)
  }

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose()
      await logout()
      navigate('/')
    } catch (err) {
      console.error(err)
    }
  }

  const Box1Options: ApexOptions = {
    chart: {
      background: 'transparent',
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: true,
      },
      zoom: {
        enabled: false,
      },
    },
    labels: [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
      'Last Week',
      'Last Month',
      'Last Year',
      'Last Decade',
    ],
    theme: {
      mode: theme.palette.mode === 'dark' ? 'light' : 'dark',
    },
    stroke: {
      colors: [theme.colors.error.main],
      curve: 'smooth',
      width: 3,
    },
    grid: {
      padding: {
        right: 5,
        left: 5,
        bottom: 5,
      },
    },
    tooltip: {
      fixed: {
        enabled: true,
      },
      x: {
        show: false,
      },
      y: {
        title: {
          formatter: function () {
            return 'Orders:'
          },
        },
      },
      marker: {
        show: true,
      },
    },
    colors: [theme.colors.error.main],
  }
  const Box1Data = [
    {
      name: 'Revenue',
      data: [465, 546, 234, 576, 554, 338, 427, 348, 586, 254, 348],
    },
  ]

  return (
    <Box display={'flex'} gap={4} alignItems={'center'}>
      {isTrial && (
        <Box
          sx={{

            height: '56px',
            padding: '16px 24px',
            background: user.licensesTimeLeft.days > 0 ? '#E5A500' : '#FCE0FF',
            borderRadius: '9px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 1,
            color: user.licensesTimeLeft.days > 0 ? '#FFFFFF' : '#C400D6',
            fontSize: '8px',
            fontWeight: 700,
            lineHeight: '21.78px',
          }}
        >
          <TimerIcon color={user.licensesTimeLeft.days > 0 ? '#FFFFFF' : '#C400D6'} />
          <Typography
            sx={{
              fontSize: '18px',
              fontWeight: 700,
              lineHeight: '21.78px',
              textAlign: 'center',
            }}
          >
            {user.licensesTimeLeft?.days || 0}{' '}
            <span
              style={{
                fontSize: '18px',
                fontWeight: 500,
                lineHeight: '21.78px',
                textAlign: 'center',
              }}
            >
              {user.licensesTimeLeft.days === 1 ? 'day' : 'days'}{' '}
              left in trial
            </span>
          </Typography>
        </Box>
      )}
      <UserBoxButton color="primary" ref={ref} onClick={handleOpen}>
        <UserAvatar alt={user.name} src={user.avatar} />
        {transformName(user.name)}
        <ExpandMoreTwoToneIcon />
      </UserBoxButton>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuUserBox
          sx={{
            minWidth: 210,
          }}
          display="flex"
        >
          <Avatar
            variant="rounded"
            alt={user.name}
            src={user.avatar}
          />
          <UserBoxText>
            <UserBoxLabel variant="body1">{user.name}</UserBoxLabel>
            <UserBoxDescription variant="body2">
              {user.jobtitle}
            </UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider
          sx={{
            mb: 0,
          }}
        />
        <Box m={1}>
          <Button color="primary" fullWidth onClick={handleLogout}>
            <LockOpenTwoToneIcon
              sx={{
                mr: 1,
              }}
            />
            {t('Sign out')}
          </Button>
        </Box>
      </Popover>
    </Box>
  )
}

export default HeaderUserbox
