import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { excludeGroup, excludeOrgMember, getGroupInfoById, getGroupList, getOrgInfo, getUserFromGroup, getUserList, includeGroup, includeOrgMember, syncDataWithGoogle } from "../apiCalls/userManagement.api"
import { userManagementKeys } from "../queryKeys"
import { GroupData, GroupRequestParams, IGroupList, IGroupMembersList, MembersRequestParams, OrganizationShortInfo, OrgMembersList } from "../types/userManagement.types"

export const useGroupData = (
    params: GroupRequestParams,
    setCount: React.Dispatch<React.SetStateAction<number>>
) =>
    useQuery<IGroupList>({
        queryKey: [userManagementKeys.groups, params],
        queryFn: () =>
            getGroupList(params).then((res) => {
                setCount(res.total)
                return res
            }),
    })

export const useUsersData = (
    groupId: string,
    params: MembersRequestParams,
    setCount: React.Dispatch<React.SetStateAction<number>>
) =>
    useQuery<IGroupMembersList>({
        queryKey: [userManagementKeys.users, params],
        queryFn: () =>
            getUserFromGroup(groupId, params).then((res) => {
                setCount(res.total)
                return res
            }),
    })

export const useOrganizationInfo = (orgId: string) => useQuery<OrganizationShortInfo>({
    queryKey: [userManagementKeys.orgInfo],
    queryFn: () => getOrgInfo(orgId)
})

export const useUsersDataFromOrganization = (
    params: MembersRequestParams,
    setCount: React.Dispatch<React.SetStateAction<number>>
) =>
    useQuery<OrgMembersList>({
        queryKey: [userManagementKeys.usersFromOrg, params],
        queryFn: () =>
            getUserList(params).then((res) => {
                setCount(res.total)
                return res
            }),
    })

export const useGroupInfo = (
    groupId: string,
) =>
    useQuery<GroupData>({
        queryKey: [userManagementKeys.groupInfo],
        queryFn: () =>
            getGroupInfoById(groupId).then((res) => {
                return res
            }),
    })

export const useSyncDataWithGoogle = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: () => syncDataWithGoogle(),
        onSuccess: () => {
            queryClient.refetchQueries({ queryKey: [userManagementKeys.groups] })
            queryClient.refetchQueries({ queryKey: [userManagementKeys.usersFromOrg] })
        },
    })

}

export const useExcludeGroup = (groupIds: string[]) => useMutation({
    mutationFn: () => excludeGroup(groupIds)
})

export const useIncludeGroup = (groupIds: string[]) => useMutation({
    mutationFn: () => includeGroup(groupIds)
})


export const useExcludeOrgMember = (groupIds: string[]) => useMutation({
    mutationFn: () => excludeOrgMember(groupIds)
})

export const useIncludeOrgMember = (groupIds: string[]) => useMutation({
    mutationFn: () => includeOrgMember(groupIds)
})

