import { FC, ReactNode, createContext, useEffect, useReducer } from 'react'
import { User } from 'src/models/user'
import axios from 'src/utils/axios'
import { verify, JWT_SECRET } from 'src/utils/jwt'
import PropTypes from 'prop-types'
import { Route, useLocation } from 'react-router-dom'
import { QueryKey, QueryObserverResult, RefetchOptions, RefetchQueryFilters, useQuery } from '@tanstack/react-query'

interface AuthState {
  isInitialized: boolean
  isAuthenticated: boolean
  user: User | null
  isOnboarded: boolean
}

interface AuthContextValue extends AuthState {
  method: 'JWT'
  login: (email: string, password: string) => Promise<void>
  logout: () => void
  register: (email: string, name: string, password: string) => Promise<void>
  registerWithGoogle: (token) => Promise<void>
}

interface AuthProviderProps {
  children: ReactNode
}

type InitializeAction = {
  type: 'INITIALIZE'
  payload: {
    isAuthenticated: boolean
    user: User | null
  }
}

type LoginAction = {
  type: 'LOGIN'
  payload: {
    user: User
  }
}

type LogoutAction = {
  type: 'LOGOUT'
}

type RegisterAction = {
  type: 'REGISTER'
  payload: {
    user: User
  }
}

type Action = InitializeAction | LoginAction | LogoutAction | RegisterAction

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  isOnboarded: false,
}

const setSession = (accessToken: string | null): void => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken)
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
  } else {
    localStorage.removeItem('accessToken')
    delete axios.defaults.headers.common.Authorization
  }
}

const handlers: Record<
  string,
  (state: AuthState, action: Action) => AuthState
> = {
  INITIALIZE: (state: AuthState, action: InitializeAction): AuthState => {
    const { isAuthenticated, user } = action.payload

    let isOnboarded
    if (user) {
      const { onboardingStep } = user
      if (onboardingStep) {
        isOnboarded = false
      } else {
        isOnboarded = true
      }
    } else {
      isOnboarded = false
    }

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      isOnboarded,
    }
  },
  LOGIN: (state: AuthState, action: LoginAction): AuthState => {
    const { user } = action.payload
    const { onboardingStep } = user
    let isOnboarded
    if (user) {
      const { onboardingStep } = user
      if (onboardingStep) {
        isOnboarded = false
      } else {
        isOnboarded = true
      }
    } else {
      isOnboarded = false
    }

    return {
      ...state,
      isAuthenticated: true,
      user,
      isOnboarded,
    }
  },
  LOGOUT: (state: AuthState): AuthState => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state: AuthState, action: RegisterAction): AuthState => {
    const { user } = action.payload

    const { onboardingStep } = user
    let isOnboarded
    if (user) {
      const { onboardingStep } = user
      if (onboardingStep) {
        isOnboarded = false
      } else {
        isOnboarded = true
      }
    } else {
      isOnboarded = false
    }

    return {
      ...state,
      isAuthenticated: true,
      user,
      isOnboarded,
    }
  },
}

const reducer = (state: AuthState, action: Action): AuthState => {
  return handlers[action.type] ? handlers[action.type](state, action) : state
}

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  registerWithGoogle: () => Promise.resolve(),
})

const useUserData = (onSuccess, onError) => {
  const accessToken = window.localStorage.getItem('accessToken')
  const fetchUser = async () => {
    const accessToken = window.localStorage.getItem('accessToken')
    if (!accessToken) {
      return null // or throw new Error("No access token found");
    }
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    const response = await axios.get('personal')
    return response.data.user
  }

  const queryKey: QueryKey = ['userData', accessToken]
  const {
    data: user,
    isError,
    isLoading,
    refetch,
  } = useQuery(queryKey, fetchUser, {
    onError,
    onSuccess: data => {
      onSuccess(data)
    },
  })

  return { user, isError, isLoading, refetch }
}

export const AuthProvider: FC<AuthProviderProps> = props => {
  const { children } = props
  const [state, dispatch] = useReducer(reducer, initialAuthState)
  const location = useLocation();

  // const onSuccessHandler = (data) => {
  //   dispatch({
  //     type: 'INITIALIZE',
  //     payload: {
  //       isAuthenticated: true,
  //       user: data,
  //     },
  //   })
  // }

  // const onErrorHandler = () => {
  //   dispatch({
  //     type: 'INITIALIZE',
  //     payload: {
  //       isAuthenticated: false,
  //       user: null,
  //     },
  //   })
  // }

  // const { user, isError, isLoading, refetch } = useUserData(
  //   onSuccessHandler,
  //   onErrorHandler
  // )


  const initialize = async (): Promise<void> => {
    try {
      const accessToken = window.localStorage.getItem('accessToken')

      if (accessToken) {
        setSession(accessToken)

        const response = await axios.get<{ user: User }>('personal')
        const { user } = response.data

        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            user,
          },
        })
      } else {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        })
      }
    } catch (err) {
      console.error(err)
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: false,
          user: null,
        },
      })
    }
  }

  useEffect(() => {
    initialize()
  }, [location])

  const login = async (email: string, password: string): Promise<void> => {
    const response = await axios.post<{
      accessToken: string
      user: User
      message: string
    }>('/login', {
      email,
      password,
    })
    if (response.data.message) {
      throw new Error(response.data.message)
    } else {
      const { accessToken, user } = response.data
      setSession(accessToken)
      dispatch({
        type: 'LOGIN',
        payload: {
          user,
        },
      })
    }
  }

  const logout = async (): Promise<void> => {
    setSession(null)
    dispatch({ type: 'LOGOUT' })
  }

  const register = async (
    email: string,
    name: string,
    password: string
  ): Promise<void> => {
    const response = await axios.post<{ accessToken: string; user: User }>(
      'register',
      {
        email,
        name,
        password,
      }
    )
    const { accessToken, user } = response.data

    window.localStorage.setItem('accessToken', accessToken)
    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    })
  }

  const registerWithGoogle = async (token: string): Promise<any> => {
    try {
      const response = await axios.post<{
        accessToken: string
        user: User
        redirectUrl?: string
        error?: string
      }>('google-sso-register', {
        token,
      })

      const { user, accessToken, error, redirectUrl } = response.data

      if (error) {
        return error
      }

      window.localStorage.setItem('accessToken', token)
      setSession(accessToken)

      initialize()

      return user
    } catch (response) {
      if (response.error) {
        console.log('resp', response)

        return response
      }
      console.log('redirect')

      if (response.redirectUrl) {
        return (window.location.href = response.redirectUrl)
      }
    }
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        register,
        registerWithGoogle,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AuthContext
