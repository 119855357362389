import axiosInt from "src/utils/axios"

export const acceptAgreementTerms = () =>
    axiosInt.post('/onboarding/acceptTerms', {
        checkboxes: {
            terms: true,
            emergencyDeclaration: true
        }
    })

export const initializeOnboarding = (data: any) =>
    axiosInt.post('/onboarding/initialize', { ...data })


export const updateOnboardingInfo = (data: any) =>
    axiosInt.post('/onboarding/contactInfo', { ...data })
