import { ArrowBack, ArrowForward } from '@mui/icons-material'
import {
    Box,
    Button,
    Checkbox,
    Container,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    Paper,
    ToggleButton,
    Typography,
    styled,
    useTheme,
} from '@mui/material'
import { Field, Form, Formik } from 'formik'
import { CheckboxWithLabel, TextField, ToggleButtonGroup } from 'formik-mui'
import { ChangeEvent } from 'react'
import { useInitializeOnboarding } from 'src/api/hooks/useOnboarding.hooks'
import organizationAPI from 'src/controllers/organizations'

const hearFromOptions = [
    'Search Engine',
    'Social Media',
    'Technology show or conference',
    'Read about it online',
    'Referral',
    'Other',
]

const planToUseOptions = [
    'Broadcast emergency alerts and warnings.',
    'Send mass notifications, such as schedule changes or class bells.',
    'Communicate personal information to specific students or employees',
    'Other',
]

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
    borderRadius: '5px !important',
    border: `1px solid ${theme.colors.alpha.black[10]} !important`,
    font: '12px normal Inter !important',
    fontFamily: 'Inter !important',
    marginRight: '12px',
    '& :last-of-type': {
        marginRight: 0,
    },
}))

const StyledToggleButton2 = styled(ToggleButton)(({ theme }) => ({
    borderRadius: '02px !important',
    border: `1px solid ${theme.colors.alpha.black[10]} !important`,
    font: '12px normal Inter !important',
    fontFamily: 'Inter !important',
    marginRight: '12px',
    '& :last-of-type': {
        marginRight: 0,
    },
    textTransform: 'unset',
}))

const StyledBorderedGroup = styled(Box)(({ theme }) => ({
    border: '1px solid #22335433',
    borderRadius: '6px',
}))

const StyledCheckbox = styled(FormControlLabel)(() => ({
    marginLeft: '0px',
    marginRight: '0px',
    '&:not(:last-of-type)': {
        borderBottom: '1px solid #22335433',
    },
}))

export default function OnboardingQuestionnaireStep({ setStep, ...otherProps }) {
    const theme = useTheme()
    const { mutate: initializeOnboarding } = useInitializeOnboarding()

    return (
        <Grid container item sx={{ p: 2, pb: 10, justifyContent: 'center' }}>
            <Formik
                initialValues={{
                    hearFrom: [],
                    planToUse: [],
                    orgType: undefined,
                    orgSize: undefined,
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true)
                    initializeOnboarding({
                        discoverySource: values.hearFrom.join(', '),
                        usage: values.planToUse.join(', '),
                        organizationType: values.orgType,
                        organizationSize: values.orgSize
                    }, {
                        onSuccess() {
                            setStep(prev => prev + 1)
                        },
                    })
                }}
                validate={values => {
                    const errors: any = {}
                    if (
                        !(
                            !!values.hearFrom.length &&
                            !!values.planToUse.length &&
                            values.orgType &&
                            values.orgSize
                        )
                    ) {
                        errors.hearFrom = 'Required'
                        errors.planToUse = 'Required'
                        errors.orgType = 'Required'
                        errors.orgSize = 'Required'
                    }
                    return errors
                }}
            >
                {({ values, submitForm, dirty, isValid, setFieldValue }) => {

                    const handleHearFromChange = (event) => {
                        const { value, checked } = event.target;
                        if (value === 'Other') {
                            // If "Other" is checked, add it without text, otherwise ensure it's removed.
                            setFieldValue(
                                'hearFrom',
                                checked ? [...values.hearFrom, value] : values.hearFrom.filter(v => !v.startsWith('Other'))
                            );
                        } else {
                            // For options other than "Other", add or remove them as before.
                            setFieldValue(
                                'hearFrom',
                                values.hearFrom.includes(value)
                                    ? values.hearFrom.filter(v => v !== value)
                                    : [...values.hearFrom, value]
                            );
                        }
                    };

                    const handlePlanToUseChange = (event) => {
                        const { value, checked } = event.target;
                        if (value === 'Other') {
                            setFieldValue(
                                'planToUse',
                                checked ? [...values.planToUse, value] : values.planToUse.filter(v => !v.startsWith('Other'))
                            );
                        } else {
                            setFieldValue(
                                'planToUse',
                                values.planToUse.includes(value)
                                    ? values.planToUse.filter(v => v !== value)
                                    : [...values.planToUse, value]
                            );
                        }
                    };

                    const handleSpecifiedOtherValueChange = (fieldName, event) => {
                        const text = event.target.value;
                        const updatedValue = `Other: ${text}`;

                        const updatedArray = values[fieldName].filter(v => !v.startsWith('Other'));
                        if (text) {
                            updatedArray.push(updatedValue);
                        }
                        setFieldValue(fieldName, updatedArray);
                    };

                    return (
                        <>
                            <Container maxWidth="md">
                                <Form>
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            flexDirection: 'column',
                                            width: '60svw',
                                        }}
                                    >
                                        <Typography variant="h2" mt={5}>
                                            👋 Welcome to Alpaca Notify
                                        </Typography>
                                        <Typography
                                            variant="subtitle2"
                                            sx={{ marginTop: 1 }}
                                        >
                                            Let's get you set up with the basics
                                            :)
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Paper
                                            sx={{
                                                p: 2,
                                                mt: 4,
                                                borderBottomLeftRadius: 0,
                                                borderBottomRightRadius: 0,
                                            }}
                                        >
                                            <Typography variant="h4">
                                                Lets start with a few questions
                                            </Typography>
                                            <Typography variant="body1">
                                                Your answers will help us tailor
                                                this experience to your
                                                organization
                                            </Typography>
                                        </Paper>
                                        <Paper
                                            sx={{
                                                borderTopLeftRadius: 0,
                                                borderTopRightRadius: 0,
                                                borderTop: `1px solid ${theme.colors.alpha.black[10]}`,
                                                p: 2,
                                            }}
                                        >
                                            <Typography
                                                variant="h5"
                                                sx={{ mb: 1 }}
                                            >
                                                How did you hear about Alpaca
                                                Notify?
                                            </Typography>

                                            <StyledBorderedGroup>
                                                <FormControl
                                                    name="hearFrom"
                                                    component="fieldset"
                                                    variant="standard"
                                                    sx={{ display: 'block' }}
                                                    onChange={handleHearFromChange}
                                                >
                                                    <FormGroup>
                                                        {hearFromOptions.map(
                                                            option => (
                                                                <StyledCheckbox
                                                                    control={
                                                                        <Checkbox
                                                                            value={
                                                                                option
                                                                            }
                                                                        />
                                                                    }
                                                                    key={option}
                                                                    name={
                                                                        option
                                                                    }
                                                                    label={
                                                                        option
                                                                    }
                                                                    checked={values.hearFrom.some(
                                                                        (el: string) => el.startsWith(option)
                                                                    )}
                                                                />
                                                            )
                                                        )}
                                                    </FormGroup>
                                                </FormControl>

                                                <Box
                                                    sx={{
                                                        padding:
                                                            '8px 12px 12px 12px',
                                                    }}
                                                >
                                                    <Field
                                                        fullWidth
                                                        component={TextField}
                                                        disabled={
                                                            !values.hearFrom.some(
                                                                (el: string) => el.startsWith("Other")
                                                            )
                                                        }
                                                        id="specifiedOtherValue"
                                                        name="specifiedOtherValue"
                                                        size="small"
                                                        label="Please specify"
                                                        onChange={(e) => handleSpecifiedOtherValueChange('hearFrom', e)}
                                                    />
                                                </Box>
                                            </StyledBorderedGroup>

                                            <Typography
                                                variant="h5"
                                                sx={{ mb: 1, mt: 2 }}
                                            >
                                                How do you plan to use Alpaca
                                                Notify?
                                            </Typography>

                                            <StyledBorderedGroup>
                                                <FormControl
                                                    name="planToUse"
                                                    component="fieldset"
                                                    variant="standard"
                                                    sx={{ display: 'block' }}
                                                    onChange={handlePlanToUseChange}
                                                >
                                                    <FormGroup>
                                                        {planToUseOptions.map(
                                                            option => (
                                                                <StyledCheckbox
                                                                    control={
                                                                        <Checkbox
                                                                            value={
                                                                                option
                                                                            }
                                                                        />
                                                                    }
                                                                    key={option}
                                                                    name={
                                                                        option
                                                                    }
                                                                    label={
                                                                        option
                                                                    }
                                                                    checked={values.planToUse.some(
                                                                        (el: string) => el.startsWith(option)
                                                                    )}
                                                                />
                                                            )
                                                        )}
                                                    </FormGroup>
                                                </FormControl>

                                                <Box
                                                    sx={{
                                                        padding:
                                                            '8px 12px 12px 12px',
                                                    }}
                                                >
                                                    <Field
                                                        fullWidth
                                                        component={TextField}
                                                        disabled={
                                                            !values.planToUse.some(
                                                                (el: string) => el.startsWith("Other")
                                                            )
                                                        }
                                                        id="specifiedOtherValue"
                                                        name="specifiedOtherValue"
                                                        size="small"
                                                        label="Please specify"
                                                        onChange={(e) => handleSpecifiedOtherValueChange('planToUse', e)}
                                                    />
                                                </Box>
                                            </StyledBorderedGroup>

                                            <Typography
                                                variant="h5"
                                                sx={{ mt: 4, mb: 1 }}
                                            >
                                                My organization is a
                                            </Typography>

                                            <Field
                                                component={ToggleButtonGroup}
                                                name="orgType"
                                                exclusive
                                                type="checkbox"
                                            >
                                                <StyledToggleButton value="publicSchool">
                                                    Public School
                                                </StyledToggleButton>
                                                <StyledToggleButton value="privateSchool">
                                                    Private School
                                                </StyledToggleButton>
                                                <StyledToggleButton value="enterpriseOrganization">
                                                    Enterprise Organization
                                                </StyledToggleButton>
                                                <StyledToggleButton value="503cNonProfit">
                                                    Nonprofit
                                                </StyledToggleButton>
                                            </Field>

                                            <Typography
                                                variant="h5"
                                                sx={{ mt: 4, mb: 1 }}
                                            >
                                                How big is your organization?
                                            </Typography>

                                            <Field
                                                component={ToggleButtonGroup}
                                                name="orgSize"
                                                type="checkbox"
                                                exclusive
                                            >
                                                <StyledToggleButton value="100">
                                                    &lt;100
                                                </StyledToggleButton>
                                                <StyledToggleButton value="300">
                                                    100-1000
                                                </StyledToggleButton>
                                                <StyledToggleButton value="500">
                                                    1000-5000
                                                </StyledToggleButton>
                                                <StyledToggleButton value="1000">
                                                    5000+
                                                </StyledToggleButton>
                                            </Field>
                                        </Paper>
                                    </Grid>
                                </Form>
                            </Container>

                            <Grid
                                item
                                justifySelf="flex-end"
                                sx={{ position: 'fixed', bottom: 0, zIndex: 99 }}
                                xs={12}
                            >
                                <Paper
                                    variant="outlined"
                                    sx={{
                                        height: '64px',
                                        width: '100svw',
                                        paddingTop: '12px',
                                        paddingBottom: '12px',
                                        borderRadius: 0,
                                    }}
                                >
                                    <Container
                                        maxWidth="md"
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <Button
                                            sx={{ width: '130px' }}
                                            variant="contained"
                                            startIcon={<ArrowBack />}
                                            onClick={() => setStep(prev => prev - 1)}
                                        >
                                            Back
                                        </Button>

                                        <Button
                                            sx={{ width: '271px' }}
                                            variant="contained"
                                            endIcon={<ArrowForward />}
                                            onClick={submitForm}
                                            disabled={!(dirty && isValid)}
                                        >
                                            Go to Setup Instructions
                                        </Button>
                                    </Container>
                                </Paper>
                            </Grid>
                        </>
                    )
                }}
            </Formik>
        </Grid>
    )
}
