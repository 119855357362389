import React, { useState, useEffect } from 'react'
import {
    Autocomplete,
    AutocompleteChangeReason,
    AutocompleteProps,
    TextField,
} from '@mui/material'

interface Country {
    name: string
    alpha2Code: string
    timezones: string[]
}

interface CountryPickerProps
    extends Omit<
        AutocompleteProps<Country, false, false, false>,
        'onChange' | 'options' | 'renderInput'
    > {
    onCountryChange: (country: Country | null) => void
    initialValue?: string | null
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
}

const CountryPicker: React.FC<CountryPickerProps> = ({
    onCountryChange,
    initialValue = "",
    setFieldValue,
    ...props
}) => {
    const [countries, setCountries] = useState<Country[]>([])
    const [selectedCountry, setSelectedCountry] = useState<Country | null>(null)

    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await fetch(
                    'https://restcountries.com/v3.1/all?fields=name,alpha2Code,timezones'
                )
                const data: any[] = await response.json()
                const formattedCountries: Country[] = data.sort((a, b) => {
                    return a.name.common.toLowerCase() < b.name.common.toLowerCase()
                      ? -1
                      : 1
                  }).map(
                    (country: any) => ({
                        name: country.name.common,
                        alpha2Code: country.alpha2Code,
                        timezones: country.timezones,
                    })
                )
                const initialCountry = formattedCountries.find(country => country.name === initialValue) || null
                if (initialCountry) {
                    setSelectedCountry(initialCountry)
                    onCountryChange(initialCountry)
                }
                setCountries(formattedCountries)
            } catch (error) {
                console.error('Error fetching countries:', error)
            }
        }

        fetchCountries()
    }, [initialValue])

    const handleCountryChange = (
        event: React.ChangeEvent<{}>,
        value: Country | null,
        reason: AutocompleteChangeReason
    ) => {
        if (reason === 'clear') {
            setSelectedCountry(null)
            onCountryChange(value)
            return
        }
        setSelectedCountry(value)
        if (value) {
            onCountryChange(value)
            setFieldValue('country', value.name)
        }
    }

    return (
        <Autocomplete
            options={countries}
            getOptionLabel={country => (country as Country).name}
            value={selectedCountry}
            onChange={handleCountryChange}
            renderInput={params => (
                <TextField {...params} label="Country" variant="outlined" />
            )}
            sx={{ width: '280px' }}
            {...props}
        />
    )
}

export default CountryPicker
