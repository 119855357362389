export const userManagementKeys = {
    groups: ['groupsList'] as const,
    users: ['usersList'] as const,
    usersFromOrg: ['usersFromOrg'] as const,
    groupInfo: ['groupInfo'] as const,
    orgInfo: ['orgInfo'] as const
}

export const importFromGoogleKeys = {
    importAll: ['importAll'] as const,
    importSelected: ['importSelected'] as const,
    fetchGoogleRes: ['fetchGoogleRes'] as const
}

export const organizationKeys = {
    fetchOrgInfo: ['fetchOrgInfo'] as const,
    updateOrgInfo: ['updateOrgInfo'] as const,
    permissionList: ['permissionList'] as const
}