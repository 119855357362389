import React from 'react'

const SyncIcon = ({ color }: { color: string }) => {
    return (
        <svg width="17" height="23" viewBox="0 0 17 23" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path d="M8.39307 3.5V0.5L4.39307 4.5L8.39307 8.5V5.5C11.7031 5.5 14.3931 8.19 14.3931 11.5C14.3931 12.51 14.1431 13.47 13.6931 14.3L15.1531 15.76C15.9331 14.53 16.3931 13.07 16.3931 11.5C16.3931 7.08 12.8131 3.5 8.39307 3.5ZM8.39307 17.5C5.08307 17.5 2.39307 14.81 2.39307 11.5C2.39307 10.49 2.64307 9.53 3.09307 8.7L1.63307 7.24C0.853066 8.47 0.393066 9.93 0.393066 11.5C0.393066 15.92 3.97307 19.5 8.39307 19.5V22.5L12.3931 18.5L8.39307 14.5V17.5Z" fill={color} />
        </svg>
    )
}

export default SyncIcon