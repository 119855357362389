import { useMutation } from "@tanstack/react-query";
import { acceptAgreementTerms, initializeOnboarding, updateOnboardingInfo } from "../apiCalls/onboarding.api";

export const useAcceptAgreements = () => useMutation({
    mutationFn: () => acceptAgreementTerms()
})

export const useInitializeOnboarding = () => useMutation<any, any, any>({
    mutationFn: (data) => initializeOnboarding(data)
})

export const useUpdateOnboardingInfo = () => useMutation<any, any, any>({
    mutationFn: (data) => updateOnboardingInfo(data)
})