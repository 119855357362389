import {
    Typography,
    Grid,
    Box,
    Button,
    styled,
    CircularProgress,
} from '@mui/material'
import React, { useContext } from 'react'
import PageTitleWrapper from '../PageTitleWrapper'
import BillingIcon from '../SvgComponents/BillingIcon'
import OrgDetails from '../SvgComponents/OrgDetails'
import PermissionIcon from '../SvgComponents/PermissionIcon'
import SyncIcon from '../SvgComponents/SyncIcon'
import OrganizationDetails from '../organisms/OrganizationDetails/OrganizationDetails'
import OrganizationSyncSettings from '../organisms/OrganizationSyncSettings/OrganizationSyncSettings'
import OrganizationBillingSettings from '../organisms/OrganizationBillingSettings/OrganizationBillingSettings'
import OrganizationPermissionsSettings from '../organisms/OrganizationPermissionsSettings/OrganizationPermissionsSettings'
import { useFetchOrgInfo } from 'src/api/hooks/useOrganization.hooks'
import AuthContext from 'src/contexts/JWTAuthContext'
import { useOrganizationInfo } from 'src/api/hooks/userManagement.hooks'
import { OrgContactInfo } from 'src/api/types/orgSettings.types'
import dayjs from 'dayjs'
import { URLSearchParamsInit, useLocation, useNavigate, useSearchParams, Outlet } from 'react-router-dom'

type AvailableTabs = 'details' | 'sync' | 'billing' | 'permissions'

interface Country {
    name: string
    alpha2Code: string
    timezones: string[]
}

const CONTACT_PLACEHOLDER: OrgContactInfo = {
    firstName: '',
    lastName: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipCode: '',
    phoneNumber: '',
    email: '',
}

interface MenuItemProps {
    activeTab: boolean
    tab: AvailableTabs
    text: string
    setActiveTab: React.Dispatch<React.SetStateAction<AvailableTabs>>
    IconComponent: ({ color }: { color: string }) => JSX.Element
    handleNavigate: (path: string) => void
}

const MenuItem = ({
    activeTab,
    tab,
    text,
    setActiveTab,
    IconComponent,
    handleNavigate
}: MenuItemProps) => {

    return (
        <Button
            sx={{
                backgroundColor: `${activeTab ? '#112645' : '#FFFFFF'}`,
                '&:hover': {
                    backgroundColor: `${activeTab ? '#112645' : 'rgba(17, 38, 69, .1)'
                        }`,
                },
                width: '100%',
                padding: '14px',
                maxHeight: '48px',
                display: 'flex',
                justifyContent: 'flex-start',
                gap: 1,
            }}
            onClick={() => handleNavigate(tab)}
        >
            <IconComponent color={activeTab ? '#F4F4F4' : '#223354'} />
            <Typography
                sx={{
                    color: `${activeTab ? '#F4F4F4' : '#223354'}`,
                    fontSize: '15px',
                    fontWeight: 600,
                    lineHeight: '18.15px',
                    textAlign: 'left',
                    '&:first-letter': {
                        textTransform: 'uppercase',
                    },
                }}
            >
                {text}
            </Typography>
        </Button>
    )
}

const OrganizationSettingsPage = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const { user } = useContext(AuthContext);

    const tabs = [
        { path: 'details', text: 'Overview', Icon: OrgDetails },
        { path: 'sync', text: 'Sync settings', Icon: SyncIcon },
        { path: 'billing', text: 'Billing', Icon: BillingIcon },
        { path: 'permissions', text: 'Permissions', Icon: PermissionIcon },
    ];

    const { data: organizationData, isLoading } = useFetchOrgInfo(user.orgId)
    const { data: organizationLicenseData, isLoading: isLicenseInfoLoading } =
        useOrganizationInfo(user.orgId)

    const [searchParams, setSearchParam] = useSearchParams()
    const tab = searchParams.get('activeTab') || 'DETAILS'

    const [activeTab, setActiveTab] = React.useState<AvailableTabs>(
        tab as AvailableTabs
    )

    const handleNavigate = (path: string) => {
        navigate(path)
    }

    return (
        <div>
            <PageTitleWrapper>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid display={'flex'} gap={'10px'} alignItems={'center'}>
                        <Typography
                            sx={{
                                fontSize: '30px',
                                fontWeight: 700,
                                lineHeight: '50px',
                                textAlign: 'left',
                                color: '#223354',
                                opacity: 0.8,
                            }}
                        >
                            Organization Settings •
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: '15px',
                                fontWeight: 400,
                                textAlign: 'left',
                                opacity: 0.7,
                                marginTop: '4.5px',
                            }}
                        >
                            Manage and customize organization information and
                            settings.
                        </Typography>
                    </Grid>
                </Grid>
            </PageTitleWrapper>

            <Box
                display={'flex'}
                gap={2}
                sx={{
                    padding: '0 24px 32px 32px',
                    height: 'calc(100vh - 90px - 78px)',
                    minHeight: '800px',
                }}
            >
                <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    flexDirection={'column'}
                    width={'100%'}
                    maxWidth={'375px'}
                >
                    <Grid
                        container
                        direction="column"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        gap={3}
                        sx={{
                            backgroundColor: '#FFFFFF',
                            padding: '24px',
                            borderRadius: '16px',
                            width: '100%',
                        }}
                    >
                        {tabs.map(tab => (
                            <MenuItem
                                activeTab={location.pathname.includes(tab.path)}
                                setActiveTab={setActiveTab}
                                tab={tab.path as AvailableTabs}
                                IconComponent={tab.Icon}
                                text={tab.text}
                                handleNavigate={handleNavigate}
                                key={tab.path}
                            />
                        ))}
                    </Grid>
                    <Box
                        sx={{
                            backgroundColor: '#FFFFFF',
                            padding: '24px',
                            borderRadius: '16px',
                        }}
                    >
                        {isLicenseInfoLoading && isLoading && (
                            <Box
                                sx={{
                                    position: 'relative',
                                    left: 0,
                                    top: 0,
                                    width: '100%',
                                    height: '100%',
                                }}
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <CircularProgress
                                    size={64}
                                    disableShrink
                                    thickness={3}
                                />
                            </Box>
                        )}
                        {!isLicenseInfoLoading && !isLoading && (
                            <>
                                <Typography
                                    sx={{
                                        fontSize: '20px',
                                        fontWeight: 700,
                                        lineHeight: '24.2px',
                                        textAlign: 'left',
                                    }}
                                >
                                    Licenses
                                </Typography>
                                <Box
                                    display={'flex'}
                                    gap={1}
                                    alignItems={'center'}
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: 700,
                                            fontSize: '50px',
                                            lineHeight: '60.51px',
                                            color: '#44D600',
                                        }}
                                    >
                                        {organizationLicenseData.totalLicenses -
                                            organizationLicenseData.licensesAvailable}
                                    </Typography>
                                    <Box>
                                        <Typography
                                            sx={{
                                                fontWeight: 600,
                                                fontSize: '15px',
                                                lineHeight: '18,15px',
                                                color: '#223354',
                                            }}
                                        >
                                            Users
                                        </Typography>
                                        <Typography>
                                            /
                                            {
                                                organizationLicenseData.totalLicenses
                                            }{' '}
                                            available
                                        </Typography>
                                    </Box>
                                </Box>
                                <Typography sx={{ fontSize: '15px' }}>
                                    exp. date:{' '}
                                    {dayjs(
                                        organizationData.data.organization
                                            .licenseInfo?.licenseExpirationDate
                                    ).format('DD.MM.YYYY') || '24.03.2024'}
                                </Typography>
                                <Button
                                    variant="contained"
                                    sx={{
                                        marginTop: '20px',
                                        width: '100%',
                                        color: '#FFFFFF',
                                        backgroundColor: '#1A75FF',
                                        borderRadius: '6px',
                                        height: '40px',
                                    }}
                                    component={'a'}
                                    href='https://support.alpacanotify.com'
                                    target={"_blank"}
                                    rel='noopener noreferrer'
                                >
                                    Contact Us
                                </Button>
                            </>
                        )}
                    </Box>
                </Box>
                {isLoading && (
                    <Box
                        sx={{
                            position: 'relative',
                            left: 0,
                            top: 0,
                            width: '100%',
                            height: '100%',
                        }}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <CircularProgress
                            size={64}
                            disableShrink
                            thickness={3}
                        />
                    </Box>
                )}
                {!isLoading && (
                    <>
                        <Outlet />
                    </>
                )}
            </Box>
        </div>
    )
}

export default OrganizationSettingsPage
