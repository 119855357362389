import styled from '@emotion/styled'
import { MenuItem, Select, Typography } from '@mui/material'
import { Dispatch, SetStateAction } from 'react'

interface ITablePaginationProps {
    countsRowsByOnePage?: number[]
    count?: number
    pages: number
    take: number
    skip: number
    setTake: Dispatch<SetStateAction<number>>
    setSkip: Dispatch<SetStateAction<number>>
}

const TablePagination = ({
    countsRowsByOnePage = [10, 25, 50],
    count,
    pages,
    skip,
    take,
    setSkip,
    setTake,
}: ITablePaginationProps) => {
    const handleStepClick = (step: number) => {
        setSkip(step !== 1 ? step : 1)
    }
    const handlePrev = () => {
        setSkip((prevState) => {
            if (prevState === 1) {
                return prevState
            } else {
                return prevState - 1
            }
        })
    }
    const handleNext = () => {
        setSkip((prevState) => {
            if (pages) {
                if (prevState + 1 > pages) {
                    return prevState
                } else {
                    return prevState + 1
                }
            }

            return prevState
        })
    }

    const handlePerPage = (value: number) => {
        setTake(value)
    }

    return (
        <StyledTableWrapper>
            <StyledCountRowsWrapper>
                {[...Array(pages)].map((_, i) => (
                    <StyledNavigationItem
                        isActive={
                            i + 1 === 1 ? skip === 1 : skip === (i + 1)
                        }
                        onClick={() => handleStepClick(i + 1)}
                        key={i}
                    >
                        {i + 1}
                    </StyledNavigationItem>
                ))}
            </StyledCountRowsWrapper>
            <StyledNavigationWrapper>
                {pages > 0 && (
                    <>
                        <li onClick={handlePrev}>
                            <img src={'/static/images/icons/arrow-left.svg'} alt='arrow prev' />
                        </li>
                        <li onClick={handleNext}>
                            <img src={'/static/images/icons/arrow-right.svg'} alt='arrow next' />
                        </li>
                    </>
                )}
            </StyledNavigationWrapper>
            <StyledCountRowsWrapper>
                <Typography>Rows per page:</Typography>
                <StyledCountRowsItem
                    onChange={(e) => handlePerPage(e.target.value as number)}
                    value={take}
                    sx={{
                        padding: 0,
                        '.MuiSvgIcon-root ': {
                            top: 'calc(50% - 0.5em)',
                        },
                        boxShadow: "none",
                        ".MuiOutlinedInput-notchedOutline": { border: 0 },
                        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                        {
                            border: 0,
                        },
                        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                            border: 0,
                        },
                        '& .MuiSelect-select': {
                            padding: '8.5px 12px'
                        }
                    }}
                >
                    {countsRowsByOnePage.map(count => (
                        <MenuItem value={count} key={count} sx={{ fontSize: 12 }}>{count}</MenuItem>
                    ))}
                </StyledCountRowsItem>
            </StyledCountRowsWrapper>
        </StyledTableWrapper>
    )
}

const StyledTableWrapper = styled.div`
  display: flex;
  height: 56px;
  align-items: center;
  justify-content: space-between;
  column-gap: 20px;
  padding-left: 16px;
  padding-right: 16px;
  ul {
    list-style: none;
    padding-left: 0;
  }
  li {
    cursor: pointer;
  }
`
const StyledCountRowsWrapper = styled.ul`
  display: flex;
  align-items: center;
  column-gap: 4px;
`
const StyledCountRowsItem = styled(Select)(({ theme }) => ({

}));
const StyledNavigationWrapper = styled.ul`
  display: flex;
  column-gap: 8px;
  align-items: center;
  li {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
const StyledNavigationItem = styled.li<{ isActive?: boolean }>`
  border: ${({ isActive }) =>
        isActive ? '1px solid #1A75FF' : '1px solid transparent'};
  border-radius: 8px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export default TablePagination