import {
    Badge,
    Paper,
    Step,
    StepLabel,
    Stepper,
    Typography,
    useTheme,
} from '@mui/material'
import { Grid } from '@mui/material'
import { useMemo, useState } from 'react'
import OnboardingQuestionnaireStep from './OnboardingStepOne'
import OnboardingStepTwo from './OnboardingStepTwo'
import OnboardingStepThree from './OnboardingStepThree'
import OnboardingStepFour from './OnboardingStepFour'
import { useLocation } from 'react-router-dom'
import NewOnboardingContactInfoStep from './NewOnboardingStepTwo'
import NewOnboardingInstallationStep from './NewOnboardingStepThree'
import NewOnboardingAgreementStep from './NewOnboardingStepFour'
import NewOnboardingStepFive from './NewOnboardingStepFive'
import OnboardingStartScreen from './OnboardingStartScreen'

const steps = [
    'Agreements',
    'Your Organization',
    'About You',
    'Install',
]

const stepMap = {
    0: OnboardingStartScreen,
    1: NewOnboardingAgreementStep,
    2: OnboardingQuestionnaireStep,
    3: NewOnboardingContactInfoStep,
    4: NewOnboardingInstallationStep,
    5: NewOnboardingStepFive,
}

export default function AlpacaOnboarding() {
    const theme = useTheme()
    const { hash } = useLocation()
    const [step, setStep] = useState(parseInt(hash[1]) || 0)
    const OnboardingStep = useMemo(() => {
        return stepMap[step]
    }, [step])

    return step > 0 ? (
        <>
            <Grid container direction="column">
                <Grid item>
                    <Paper>
                        <Grid
                            container
                            sx={{
                                justifyContent: 'space-between',
                                paddingRight: 2,
                                paddingLeft: 2,
                            }}
                        >
                            <Grid
                                item
                                sx={{ display: 'flex', alignItems: 'center' }}
                                xs={3}
                            >
                                <img
                                    width="48"
                                    src="static/images/logo/alpaca-logo-no-text.svg"
                                    alt="Alpaca Logo"
                                ></img>
                                <Badge
                                    badgeContent="Beta"
                                    color="success"
                                    sx={{
                                        '& .MuiBadge-badge': {
                                            transform:
                                                'translateX(45px) translateY(5px)',
                                        },
                                    }}
                                >
                                    <Typography
                                        variant="h3"
                                        sx={{
                                            paddingLeft: 1.5,
                                            fontFamily: 'all-round-gothic',
                                        }}
                                    >
                                        Alpaca Notify
                                    </Typography>
                                </Badge>
                            </Grid>
                            <Grid item xs={6}>
                                <Stepper
                                    activeStep={step}
                                    alternativeLabel
                                    sx={{
                                        backgroundColor:
                                            theme.palette.background.paper,
                                    }}
                                >
                                    {steps.map(label => (
                                        <Step key={label}>
                                            <StepLabel>{label}</StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                            </Grid>
                            <Grid item xs={3}></Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <OnboardingStep setStep={setStep}></OnboardingStep>
            </Grid>
        </>
    ) : (
        <OnboardingStartScreen setStep={setStep} />
    )
}
