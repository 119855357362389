import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { useFetchOrgInfo } from 'src/api/hooks/useOrganization.hooks';
import { OrganizationInfo } from 'src/api/types/orgSettings.types';
import AuthContext from './JWTAuthContext';

interface OrganizationSettingsContextValue {
    organizationData: OrganizationInfo | null;
    isLoading: boolean;
}

// Define the props for the provider
interface OrganizationProviderProps {
    children: ReactNode;
}

const OrganizationSettingsContext = createContext<OrganizationSettingsContextValue>({
    organizationData: null,
    isLoading: true,
});

export const useOrganizationSettings = () => useContext(OrganizationSettingsContext);

export const OrganizationSettingsProvider: React.FC<OrganizationProviderProps> = ({ children }) => {
    const { user } = useContext(AuthContext);
    const { data: organizationData, isLoading } = useFetchOrgInfo(user.orgId)

    return (
        <OrganizationSettingsContext.Provider value={{ organizationData: organizationData?.data?.organization, isLoading }}>
            {children}
        </OrganizationSettingsContext.Provider>
    );
};