import { ArrowForward } from '@mui/icons-material'
import { Box, Button, Paper, Typography, styled } from '@mui/material'

const StyledCard = styled(Paper)(() => ({
    padding: 16,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flexDirection: 'column',
}))

const OnboardingStartScreen = ({ setStep }) => {
    return (
        <Box
            sx={{
                width: '100svw',
                height: '100svh',
                background:
                    "url('/static/images/illustrations/OnboardingBg.jpg')",
                backgroundSize: 'cover',
            }}
        >
            <Paper
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '934px',
                    padding: '56px 160px',
                    borderRadius: 4,
                    textAlign: 'center',
                }}
            >
                <img
                    src="/static/images/logo/alpaca-logo-no-text.svg"
                    alt="Alpaca logo"
                    width={140}
                />

                <Typography variant="h1" textAlign="center" mt={3}>
                    👋 Welcome to Alpaca Notify!
                </Typography>

                <Typography
                    variant="subtitle2"
                    sx={{
                        marginTop: 1.5,
                        fontSize: '20px',
                        fontWeight: 500,
                        textAlign: 'center',
                    }}
                >
                    Here’s a free trial to get you started.
                </Typography>

                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(2, 1fr)',
                        columnGap: '21px',
                        mt: 9,
                    }}
                >
                    <StyledCard>
                        <Box
                            sx={{
                                height: '24px',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Typography sx={{ fontSize: '24px' }}>
                                📅
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '15px',
                                    textTransform: 'uppercase',
                                    fontWeight: 600,
                                }}
                            >
                                Days
                            </Typography>
                        </Box>

                        <Box sx={{ mt: 7 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography
                                    sx={{
                                        fontSize: '50px',
                                        lineHeight: '60px',
                                        fontWeight: 700,
                                        color: '#44D600',
                                    }}
                                >
                                    30
                                </Typography>

                                <Box sx={{ ml: 1.5, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                    <Typography
                                        sx={{
                                            fontSize: '15px',
                                            lineHeight: '18px',
                                            fontWeight: 600,
                                            color: '#000',
                                        }}
                                    >
                                        Days
                                    </Typography>

                                    <Typography
                                        sx={{
                                            mt: 0.5,
                                            fontSize: '15px',
                                            lineHeight: '18px',
                                            color: '#4D4D4D',
                                        }}
                                    >
                                        /30 available
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </StyledCard>

                    <StyledCard>
                        <Box
                            sx={{
                                height: '24px',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Typography sx={{ fontSize: '24px' }}>
                                👩‍🎓
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '15px',
                                    textTransform: 'uppercase',
                                    fontWeight: 600,
                                }}
                            >
                                Licenses
                            </Typography>
                        </Box>

                        <Box sx={{ mt: 7 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography
                                    sx={{
                                        fontSize: '50px',
                                        lineHeight: '60px',
                                        fontWeight: 700,
                                        color: '#44D600',
                                    }}
                                >
                                    1000
                                </Typography>

                                <Box sx={{ ml: 1.5, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                    <Typography
                                        sx={{
                                            fontSize: '15px',
                                            lineHeight: '18px',
                                            fontWeight: 600,
                                            color: '#000',
                                        }}
                                    >
                                        Users
                                    </Typography>

                                    <Typography
                                        sx={{
                                            mt: 0.5,
                                            fontSize: '15px',
                                            lineHeight: '18px',
                                            color: '#4D4D4D',
                                        }}
                                    >
                                        /1000 available
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </StyledCard>
                </Box>

                <Typography
                    sx={{
                        fontSize: '16px',
                        color: 'rgba(34, 51, 84, 0.5)',
                        textAlign: 'center',
                        mt: 9,
                    }}
                >
                    Let's get started.
                </Typography>

                <Box sx={{ mt: 9, textAlign: 'center' }}>
                    <Button
                        variant="contained"
                        endIcon={<ArrowForward />}
                        sx={{ px: 4 }}
                        onClick={() => setStep(1)}
                    >
                        Start Onboarding
                    </Button>
                </Box>
            </Paper>
        </Box>
    )
}

export default OnboardingStartScreen
