import React, { useState, useEffect } from 'react'
import { Autocomplete, AutocompleteProps, TextField } from '@mui/material'

interface TimeZone {
    label: string
    value: string
}

interface TimezonePickerProps
    extends Omit<
        AutocompleteProps<TimeZone, false, false, false>,
        'onChange' | 'options' | 'renderInput'
    > {
    selectedCountry: { timezones: string[] }
    initialValue?: string | undefined
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
}

const TimeZonePicker: React.FC<TimezonePickerProps> = ({
    selectedCountry,
    initialValue = "",
    setFieldValue,
    ...props
}) => {
    const [timeZones, setTimeZones] = useState<TimeZone[]>([])
    const [selectedTimeZone, setSelectedTimeZone] = useState<string>(initialValue)

    useEffect(() => {
        if (selectedCountry) {
            const formattedTimeZones: TimeZone[] =
                selectedCountry.timezones.map((tz: string) => ({
                    label: tz,
                    value: tz,
                }))
            setTimeZones(formattedTimeZones)

        }
    }, [selectedCountry])

    const handleTimeZoneChange = (
        event: React.ChangeEvent<{}>,
        value: TimeZone | null
    ) => {
        setSelectedTimeZone(value?.value || '')
        setFieldValue('timezone', value?.value)
    }

    return (
        <Autocomplete
            options={timeZones}
            getOptionLabel={option => (option as TimeZone).label}
            value={timeZones.find(tz => tz.value === selectedTimeZone) || null}
            onChange={handleTimeZoneChange}
            renderInput={params => (
                <TextField {...params} label="Time Zone" variant="outlined" />
            )}
            sx={{ width: '280px' }}
            {...props}
        />
    )
}

export default TimeZonePicker
