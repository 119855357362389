import { RouteObject } from 'react-router'
import { Suspense, lazy } from 'react'
import SuspenseLoader from 'src/components/SuspenseLoader'

import Authenticated from 'src/components/Authenticated'
import { Navigate } from 'react-router-dom'

import BaseLayout from 'src/layouts/BaseLayout'
import ExtendedSidebarLayout from 'src/layouts/ExtendedSidebarLayout'
import AlpacaCalendarPage from 'src/components/pages/AlpacaCalendarPage'

import accountRoutes from './account'
import baseRoutes from './base'

import UserManagementIndex from '../content/management/Users/index'
import AlpacaLogging from 'src/components/organisms/AlpacaLogging/AlpacaLogging'
import AlpacaWebhookManager from 'src/components/organisms/AlpacaWebhookManager/AlpacaWebhookManager'
import AlpacaOnboarding from 'src/components/organisms/AlpacaOnboarding/AlpacaOnboarding'
import GroupManagementPage from 'src/components/pages/GroupManagementPage'
import OrganizationSettingsPage from 'src/components/pages/OrganizationSettingsPage'
import { OrganizationSettingsProvider } from 'src/contexts/OrganizationSettingsContext'

const Loader = Component => props =>
(
    <Suspense fallback={<SuspenseLoader />}>
        <Component {...props} />
    </Suspense>
)

const NotificationDispatchPage = Loader(
    lazy(() => import('src/components/pages/NotificationDispatchPage'))
)

const QuickSendSettingsPage = Loader(
    lazy(() => import('src/components/pages/QuickSendSettingsPage'))
)

const TemplateManagerPage = Loader(
    lazy(() => import('src/components/pages/TemplateManagerPage'))
)
/*
const AlpacaCalendarPage = Loader(
    lazy(() => import('src/components/pages/AlpacaCalendarPage'))
)
*/

const UserManagementPage = Loader(
    lazy(() => import('src/components/pages/UserManagementPage'))
)

const OrganizationDetails = Loader(lazy(() => import('src/components/organisms/OrganizationDetails/OrganizationDetails')));
const OrganizationSyncSettings = Loader(lazy(() => import('src/components/organisms/OrganizationSyncSettings/OrganizationSyncSettings')));
const OrganizationBillingSettings = Loader(lazy(() => import('src/components/organisms/OrganizationBillingSettings/OrganizationBillingSettings')));
const OrganizationPermissionsSettings = Loader(lazy(() => import('src/components/organisms/OrganizationPermissionsSettings/OrganizationPermissionsSettings')));

const router: RouteObject[] = [
    {
        path: 'account',
        children: accountRoutes,
    },
    {
        path: '',
        element: <BaseLayout />,
        children: baseRoutes,
    },

    // App Routing
    {
        path: '/welcome',
        element: <AlpacaOnboarding></AlpacaOnboarding>,
    },
    {
        path: '/',
        element: (
            <Authenticated>
                <ExtendedSidebarLayout />
            </Authenticated>
        ),
        children: [
            {
                path: 'library',
                element: <TemplateManagerPage />,
            },
            {
                path: 'webhooks',
                element: <AlpacaWebhookManager></AlpacaWebhookManager>,
            },
            {
                path: 'calendar',
                element: <AlpacaCalendarPage />,
            },
            {
                path: 'compose',
                element: <NotificationDispatchPage />,
            },
            {
                path: 'google-users',
                element: <UserManagementIndex />,
            },
            {
                path: 'quick-send-settings',
                element: <QuickSendSettingsPage />,
            },
            {
                path: 'logging',
                element: <AlpacaLogging></AlpacaLogging>,
            },
            {
                path: 'user-management',
                element: <UserManagementPage />,
            },
            {
                path: 'user-management/:groupId',
                element: <GroupManagementPage />,
            },
            {
                path: 'organization-settings',
                element: (
                    <OrganizationSettingsProvider>
                        <OrganizationSettingsPage />
                    </OrganizationSettingsProvider>
                ),
                children: [
                    { path: '', element: <Navigate to="details" replace /> },
                    { path: 'details', element: <OrganizationDetails /> },
                    { path: 'sync', element: <OrganizationSyncSettings /> },
                    { path: 'billing', element: <OrganizationBillingSettings /> },
                    { path: 'permissions', element: <OrganizationPermissionsSettings /> },
                ],
            },
        ],
    },
]

export default router
