import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { getOrganizationInfo, getUserPermissionList, updateMemberRole, updateOrganizationInfo, userUpdateAvatar } from "../apiCalls/orgSettings.api";
import { OrganizationInfoResponse, OrgMembersPermissionList, UpdateOrgInfoBody } from "../types/orgSettings.types";
import { organizationKeys } from "../queryKeys";
import { AxiosError, AxiosResponse } from "axios";
import { PermissionRequestParams } from "../types/userManagement.types";

export const useFetchOrgInfo = (orgId: string) => useQuery<AxiosResponse<OrganizationInfoResponse>>({
    queryKey: [organizationKeys.fetchOrgInfo],
    queryFn: () => getOrganizationInfo(orgId)
})

export const useUpdateOrgInfo = (orgId: string) => {
    const queryClient = useQueryClient()
    return useMutation<any, any, UpdateOrgInfoBody>({

        mutationFn: (data) => updateOrganizationInfo(orgId, data),
        onSuccess() {
            queryClient.invalidateQueries({ queryKey: [organizationKeys.fetchOrgInfo] })
        }
    })
}

export const usePermissionData = (
    params: PermissionRequestParams,
    setCount: React.Dispatch<React.SetStateAction<number>>
) =>
    useQuery<OrgMembersPermissionList>({
        queryKey: [organizationKeys.permissionList, params],
        queryFn: () =>
            getUserPermissionList(params).then((res) => {
                setCount(res.total)
                return res
            }),
    })

export const useUpdateMemberRole = (groupId: string) => useMutation<any, any, any>({
    mutationFn: (data) => updateMemberRole(groupId, data),
})

export const useUserUpdateAvatar = ({
    orgId,
    onSuccessHandler
}: {
    orgId: string
    onSuccessHandler?: (url: string) => void
}) =>
    useMutation<any, AxiosError, FormData>({
        mutationFn: (data) => userUpdateAvatar(orgId, data),
        onSuccess: (data) => {
            onSuccessHandler(data.url)
        },
    })