import styled from '@emotion/styled';
import { MenuItem, SelectChangeEvent, FormControl, Box, Select, Typography } from '@mui/material';
import React from 'react'
import { useExcludeOrgMember, useIncludeOrgMember } from 'src/api/hooks/userManagement.hooks';
import { IGroupMemberItem } from 'src/hooks/useGroupManagementTable';

const CustomSelect = styled(Select)(({ theme }) => ({
    height: '32px',
    width: '106px',
    borderRadius: '9px',
    padding: '0 12px',
    paddingRight: 0,
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '14.52px',
    textAlign: 'center',
}));

const CustomMenuItem = styled(MenuItem)(() => ({
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '14.52px',
    textAlign: 'center',
}))

const StatusChip = ({ status, id }: { status: number, id: string }) => {
    // excluded = 1
    // included = 0
    const [chipStatus, setNewStatus] = React.useState(status);
    const { mutate: excludeMutation } = useExcludeOrgMember([id])
    const { mutate: includeMutation } = useIncludeOrgMember([id])

    const handleChange = (event: SelectChangeEvent<number>) => {
        if (event && event.target.value === 1) {
            excludeMutation(void 0, {
                onSuccess(data) {
                    setNewStatus(event.target.value as number);
                },
            })
        } else {
            includeMutation(void 0, {
                onSuccess(data) {
                    setNewStatus(event.target.value as number);
                },
            })
        }
    };
    return (
        <FormControl fullWidth>
            <CustomSelect id="mySelect" value={chipStatus} style={{
                backgroundColor: `${!!!chipStatus ? '#DEFFD1' : '#FFE8EC'}`,
                color: `${!!!chipStatus ? '#37AC00' : '#FF1A43'}`
            }}
                sx={{
                    maxWidth: '50%',
                    padding: 0,
                    '.MuiSvgIcon-root ': {
                        fill: `${!!!chipStatus ? '#37AC00' : '#FF1A43'}`
                    },
                    boxShadow: "none",
                    ".MuiOutlinedInput-notchedOutline": { border: 0 },
                    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                        border: 0,
                    },
                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                        border: 0,
                    },
                    '& .MuiSelect-select': {
                        padding: '8.5px 12px'
                    }
                }}
                onChange={handleChange}
            >
                <CustomMenuItem value={0} sx={{ color: '#37AC00 !important' }}>Imported</CustomMenuItem>
                <CustomMenuItem value={1} sx={{ color: '#FF1A43 !important' }}>Excluded</CustomMenuItem>
            </CustomSelect>
        </FormControl>
    )
}

const GroupMemberTableItem = ({ gridTemplateColumns, data }: { gridTemplateColumns: string, data: IGroupMemberItem }) => {
    return (
        <Box
            sx={{
                display: 'grid',
                gridTemplateColumns: gridTemplateColumns,
                alignItems: 'center',
                py: '12px',
            }}
            key={data.googleId}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Box sx={{ width: '40px', height: '40px' }}>
                    <img src='/static/images/icons/profile-placeholder.svg' alt='profile' style={{ width: '40px', height: '40px' }} />
                </Box>
                <Typography sx={{
                    fontSize: '15px',
                    fontWeight: 600,
                    lineHeight: '18.15px',
                    textAlign: 'left',
                    color: "#000000"
                }}>{data.name}</Typography>
            </Box>
            <Typography sx={{
                fontSize: '15px',
                fontWeight: 400,
                lineHeight: '18.15px',
                textAlign: 'left',
                color: "#000000"
            }}>{data.googleId}</Typography>
            <StatusChip status={data.status} id={data.id} />
        </Box>
    )
}

export default GroupMemberTableItem