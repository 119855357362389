import { Box, ButtonBase, Typography } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import CircularProgress from '@mui/material/CircularProgress'
import styled from '@emotion/styled'
import PermissionTableItem from 'src/components/molecules/PermissionTableItem/PermissionTableItem'

interface ITableBodyProps<T,> {
    columns: GridColDef[]
    rows: T[]
    isLoading: boolean
    notFoundMessage?: string
    sortType: string
    handlePressSort: (sortType: string) => void,
    itemComponent: React.ComponentType<{ data: T, gridTemplateColumns: string }>
    itemAdditionalProps?: object
}

const TableBody = <T,>({
    columns,
    rows,
    isLoading,
    notFoundMessage,
    handlePressSort,
    sortType,
    itemComponent,
    itemAdditionalProps
}: ITableBodyProps<T>) => {
    return (
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', minHeight: '56px' }}>
            <TBody
                isLoading={isLoading}
                notFoundMessage={notFoundMessage}
                rows={rows}
                columns={columns}
                itemComponent={itemComponent}
                itemAdditionalProps={itemAdditionalProps}
            />
        </Box>
    )
}

const setGridTemplateColumns = (columns: GridColDef[]) => {
    return columns
        .map(
            (column) =>
                (column.width && `minmax(${column.width}px, ${(column.maxWidth && column.maxWidth + 'px') || '1fr'} )`) || 100 / columns.length + '%',
        )
        .join(' ')
}

interface ITBodyProps<T,> {
    rows: T[]
    columns: GridColDef[]
    isLoading: boolean
    notFoundMessage?: string
    itemComponent: React.ComponentType<{ data: T, gridTemplateColumns: string }>;
    itemAdditionalProps?: object
}

const TBody = <T,>({
    rows,
    columns,
    isLoading,
    notFoundMessage = 'There are no data to display at the moment.',
    itemComponent: ItemComponent,
    itemAdditionalProps
}: ITBodyProps<T>) => {
    const isRows = Boolean(rows.length)
    return (
        <Box
            sx={{
                position: 'relative',
                width: 'calc(100% + 48px)',
                ml: -3,
                display: 'flex',
                flexDirection: 'column',
                rowGap: '7px',
                minHeight: '56px',
                pl: 4,
                overflowY: 'scroll',
                flex: '1 1 auto'
            }}
        >
            {!isLoading &&
                isRows &&
                rows.map((row, rowIndex) => (
                    <ItemComponent gridTemplateColumns={setGridTemplateColumns(columns)} data={row} {...itemAdditionalProps} key={rowIndex} />
                ))}
            {!isLoading && !isRows && (
                <StyledNotFoundMessage>{notFoundMessage}</StyledNotFoundMessage>
            )}
            {isLoading && (
                <Box
                    sx={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flex: 1,
                        flexGrow: 1,
                        ml: -4
                    }}
                >
                    <CircularProgress />
                </Box>
            )}
        </Box>
    )
}

const StyledNotFoundMessage = styled.p`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
`

export default TableBody