import {
    Box,
    Button,
    ButtonBase,
    Container,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    MenuItem,
    Select,
    SelectChangeEvent,
    styled,
    SxProps,
    TextField,
    Typography,
    useTheme,
} from '@mui/material'
import { GridColDef, GridToolbarContainer } from '@mui/x-data-grid'
import React, { Dispatch, SetStateAction } from 'react'
import useAuth from 'src/hooks/useAuth'
import { SearchTwoTone } from '@mui/icons-material'
import TablePagination from './components/TablePagination'
import TableBody from './components/TableBody'
import SortIcon from 'src/components/atoms/SortIcon/SortIcon'

interface ITableProps<T,> {
    sxPropsWrapper?: SxProps
    rows: T[]
    columns: GridColDef[]
    isLoading: boolean
    pagination?: {
        count?: number
        take: number
        skip: number
        countsRowsByOnePage: number[]
        setSkip: Dispatch<SetStateAction<number>>
        setTake: Dispatch<SetStateAction<number>>
    }
    toolbar: JSX.Element
    notFoundMessage?: string
    handlePressSort: (sortType: string) => void
    sortType: string
    sortOrder?: string
    itemComponent: React.ComponentType<{ data: T, gridTemplateColumns: string }>
    itemAdditionalProps?: object
}

interface ITHeadProps {
    columns: GridColDef[]
    handlePressSort: (sortType: string) => void
    sortType: string
    sortOrder?: string
}

const setGridTemplateColumns = (columns: GridColDef[]) => {
    return columns
        .map(
            (column) =>
                (column.width && `minmax(${column.width}px, ${(column.maxWidth && column.maxWidth + 'px') || '1fr'} )`) || 100 / columns.length + '%',
        )
        .join(' ')
}

const THead = ({ columns, handlePressSort, sortType, sortOrder }: ITHeadProps) => {
    return (
        <Box
            sx={{
                width: 'calc(100% + 48px)',
                ml: -3,
                minHeight: '64px',
                mb: '8px',
                display: 'grid',
                gridTemplateColumns: setGridTemplateColumns(columns),
                alignItems: 'center',
                pl: '32px',
                bgcolor: '#F8F8F8CC',
                borderWidth: '1px 0px 1px 0px',
                borderStyle: 'solid',
                borderColor: '#22335433'
            }}
        >
            {
                columns.map((column) => (
                    <Box key={column.field} display={'flex'} alignItems={'flex-end'} gap={.75}>
                        <Typography
                            sx={{
                                textTransform: "uppercase",
                                fontSize: '15px',
                                fontWeight: 600,
                                lineHeight: '18.15px',
                                textAlign: 'left',
                            }}
                        >
                            {column.headerName}
                        </Typography>
                        {column.sortable && <>{column.field === sortType && sortOrder === 'asc' ? <ButtonBase onClick={() => handlePressSort(column.field)}><SortIcon color={column.field === sortType ? '#1A75FF' : '#4D5B75'} /></ButtonBase> : <ButtonBase onClick={() => handlePressSort(column.field)} sx={{ transform: 'scale(1, -1)' }}><SortIcon color={column.field === sortType ? '#1A75FF' : '#4D5B75'} /></ButtonBase>}</>}
                    </Box>
                ))
            }
        </Box >
    )
}

const UserManagementTable = <T,>({
    sxPropsWrapper,
    columns,
    rows,
    pagination,
    isLoading,
    notFoundMessage,
    toolbar,
    handlePressSort,
    sortType,
    sortOrder,
    itemComponent,
    itemAdditionalProps
}: ITableProps<T>) => {
    const acc = useAuth()
    const theme = useTheme()

    return (
        <Container
            maxWidth='xl'
            sx={{
                // height: '100%',

                borderRadius: '16px',
                px: 0,
                backgroundColor: '#FFFFFF',
                boxShadow: '10px 4px 40px 0px #E0E9F380',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                flex: 1, // Take up all available space
                overflow: 'hidden',
                ...sxPropsWrapper,
            }}
        >
            {toolbar}
            <THead columns={columns} handlePressSort={handlePressSort} sortType={sortType} sortOrder={sortOrder} />
            <TableBody
                notFoundMessage={notFoundMessage}
                columns={columns}
                rows={rows}
                isLoading={isLoading}
                sortType={sortType}
                handlePressSort={handlePressSort}
                itemComponent={itemComponent}
                itemAdditionalProps={itemAdditionalProps}
            />
            <hr style={{ width: 'calc(100% + 48px)', marginLeft: '-24px', marginTop: 'auto' }} />
            {pagination && Boolean(pagination.count) && (
                <TablePagination
                    count={pagination.count}
                    countsRowsByOnePage={pagination.countsRowsByOnePage}
                    skip={pagination.skip}
                    take={pagination.take}
                    pages={
                        pagination.count
                            ? Math.ceil(pagination?.count / pagination.take)
                            : 0
                    }
                    setSkip={pagination.setSkip}
                    setTake={pagination.setTake}
                />
            )}
        </Container>
    )
}

export default UserManagementTable
