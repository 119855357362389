import { SearchTwoTone } from "@mui/icons-material";
import { InputAdornment, TextField } from "@mui/material";

export function SearchField(props) {
    const { searchTerm, setSearchTerm } = props;
    return (
        <TextField
            key="searchBox"
            label={searchTerm ? ' ' : 'Type your search terms here...'}
            fullWidth
            autoFocus
            focused
            size="small"
            variant="outlined"
            sx={{
                borderRadius: '8px',
                borderWidth: '8px',
                '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(34, 51, 84, 0.2)',
                },
                "& .MuiInputBase-root": {
                    height: '40px',
                    lineHeight: '40px'
                },
                "& .MuiFormLabel-root": {
                    lineHeight: '22px'
                },
                '& label.Mui-focused': {
                    color: 'rgba(34, 51, 84, 0.2)',
                  },
                 ' & .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: 'rgba(34, 51, 84, 0.2) !important',
                    }
                  }
            }}
            value={searchTerm}
            InputLabelProps={{
                shrink: false,
                sx: {
                    pl: 3.5
                }
            }}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
                sx: {},
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchTwoTone color="primary"></SearchTwoTone>
                    </InputAdornment>
                )
            }}
        />
    )
}