import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import { SnackbarProvider } from 'notistack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import AppInit from './components/AppInit';

import { GoogleOAuthProvider } from '@react-oauth/google';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { AuthProvider } from './contexts/JWTAuthContext';

const queryClient = new QueryClient();

function App() {

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <ThemeProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_AUTH_TOKEN}>
              <SnackbarProvider
                maxSnack={6}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
              >
                <CssBaseline />
                <AppInit />
                <ReactQueryDevtools initialIsOpen={false} />
              </SnackbarProvider>
            </GoogleOAuthProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}
export default App;
