import React from 'react'

const SortIcon = ({color}: {color: string}) => {
    return (
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_191_6135)">
                <path d="M2.88306 15H7.88306V13.3333H2.88306V15ZM2.88306 5V6.66667H17.8831V5H2.88306ZM2.88306 10.8333H12.8831V9.16667H2.88306V10.8333Z" fill={color} />
            </g>
            <defs>
                <clipPath id="clip0_191_6135">
                    <rect width="20" height="20" fill="white" transform="translate(0.383057)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default SortIcon