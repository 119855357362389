import type { ReactNode } from 'react'

import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

export interface MenuItem {
    link?: string
    icon?: ReactNode
    badge?: string
    badgeTooltip?: string
    isPrivate?: boolean
    items?: MenuItem[]
    name: string
}

export interface MenuItems {
    items: MenuItem[]
    heading: string
}

const menuItems: MenuItems[] = [
    {
        heading: '',
        items: [
            {
                name: 'Notifications',
                link: '',
                icon: ForumOutlinedIcon,
                items: [
                    {
                        name: 'Compose',
                        link: '/compose',
                    },
                    {
                        name: 'Calendar',
                        link: '/calendar',
                    },
                    {
                        name: 'Library',
                        link: '/library',
                    },
                    {
                        name: 'Reports',
                        link: '/logging',
                    },
                ],
            },
            {
                name: 'Settings',
                link: '',
                isPrivate: true,
                icon: SettingsOutlinedIcon,
                items: [
                    {
                        name: 'Overview',
                        link: '/organization-settings',
                    },
                    {
                        name: 'Quick Send',
                        link: '/quick-send-settings',
                    },
                    {
                        name: 'Students & Groups',
                        link: '/user-management',
                    },
                    {
                        name: 'Webhooks',
                        link: '/webhooks',
                    },
                ],
            },
            {
                name: 'Support',
                link: 'https://support.alpacanotify.com/en/',
                icon: HelpOutlineOutlinedIcon,
            },
        ],
    },
]

export default menuItems
