import {
    Typography,
    Container,
    Card,
    Box,
    Button,
    Grid,
    ButtonBase,
    Fade,
    InputAdornment,
    Popper,
    styled,
    TextField,
    CircularProgress,
} from '@mui/material'
import React, { Suspense } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import UserManagementTable from '../organisms/UserManagementTable/UserManagementTable'
import PageTitleWrapper from '../PageTitleWrapper'
import useGroupManagementTable, {
    IGroupMemberItem,
} from '../../hooks/useGroupManagementTable'
import { SearchTwoTone } from '@mui/icons-material'
import { useGroupInfo } from 'src/api/hooks/userManagement.hooks'
import SuspenseLoader from '../SuspenseLoader'
import GroupMemberTableItem from '../molecules/GroupMemberTableItem/GroupMemberTableItem'
import { SearchField } from '../molecules/SearchField/SearchField'
import { LoadingButton } from '@mui/lab'
import { userManagementKeys } from 'src/api/queryKeys'
import { useQueryClient } from '@tanstack/react-query'

const StyledLoadingBtn = styled(LoadingButton)(() => ({
    height: '40px',
    border: '1px solid rgba(26, 117, 255, 0.5)',
    '&:focus': {
        border: '1px solid rgba(26, 117, 255, 0.5)',
        outline: 'none',
    },
    '&:hover': {
        border: '1px solid rgba(26, 117, 255, 0.5)',
    },
    '&:active': {
        border: '1px solid rgba(26, 117, 255, 0.5)',
    },
    '&:disabled': {
        border: '1px solid #ccc',
    },
}))

const GroupManagementPage = () => {
    const { groupId } = useParams()
    const navigate = useNavigate()
    const groupManagementData = useGroupManagementTable({ groupId })
    const groupInfo = useGroupInfo(groupId)

    function CustomToolbar() {
        const queryClient = useQueryClient()
        return (
            <Box key="customToolbar" sx={{ p: 2 }}>
                <Grid container justifyContent="space-between">
                    <Grid item xs={11.5}>
                        <SearchField
                            searchTerm={groupManagementData.inputState}
                            setSearchTerm={groupManagementData.setInputState}
                        />
                    </Grid>
                    <Grid item>
                        <StyledLoadingBtn
                           loading={groupManagementData.isLoading}
                           variant="outlined"
                            sx={{
                                height: '40px',
                                width: '40px',
                                px: 0,
                                minWidth: '40px',
                            }}
                            onClick={() =>
                                queryClient.refetchQueries({
                                    queryKey: [
                                        userManagementKeys.users
                                    ],
                                })
                            }
                        >
                            <img
                                src={'/static/images/icons/Refresh-icon.svg'}
                                alt="refresh"
                                style={{ display: groupManagementData.isLoading ? 'none' : 'block' }}
                            />
                        </StyledLoadingBtn>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    return (
        <div>
            <PageTitleWrapper>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid display={'flex'} gap={'10px'} alignItems={'center'}>
                        <Typography
                            sx={{
                                fontSize: '30px',
                                fontWeight: 700,
                                lineHeight: '50px',
                                textAlign: 'left',
                                color: '#223354',
                                opacity: 0.8,
                            }}
                        >
                            Organization Members •
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: '15px',
                                fontWeight: 400,
                                textAlign: 'left',
                                opacity: 0.7,
                                marginTop: '4.5px',
                            }}
                        >
                            Import and manage your users and groups
                        </Typography>
                    </Grid>
                </Grid>
            </PageTitleWrapper>
            <Box
                maxWidth="xl"
                sx={{
                    height: 'calc(100dvh - 90px - 78px - 32px)',
                    display: 'flex',
                    flexDirection: 'column',
                    px: '24px',
                }}
            >
                <Box maxWidth="xl">
                    {groupInfo.data && !groupInfo.isLoading && (
                        <Card
                            sx={{
                                borderRadius: '22px',
                                p: 2,
                                my: 4,
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Box display={'flex'} gap={3}>
                                <ButtonBase onClick={() => navigate(-1)}>
                                    <img
                                        src="/static/images/icons/go-back-icon.svg"
                                        alt="go-back"
                                    />
                                </ButtonBase>
                                <Box sx={{ gap: 1 }}>
                                    <Typography
                                        sx={{
                                            fontSize: 20,
                                            fontWeight: 700,
                                            lineHeight: '24.2px',
                                        }}
                                    >
                                        {groupInfo.data.name}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 16,
                                            lineHeight: '19.36px',
                                        }}
                                    >
                                        {groupInfo.data.domain}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', gap: 2 }}>
                                <Button
                                    variant="outlined"
                                    size="small"
                                    sx={{ height: '40px' }}
                                    startIcon={
                                        <img
                                            src="/static/images/icons/Sync-icon.svg"
                                            alt="sync"
                                        />
                                    }
                                    onClick={() => {}}
                                >
                                    Sync
                                </Button>
                                <Button
                                    variant="contained"
                                    size="small"
                                    sx={{ height: '40px' }}
                                    startIcon={
                                        <img
                                            src="/static/images/icons/Import-icon.svg"
                                            alt="sync"
                                        />
                                    }
                                    onClick={() => {}}
                                >
                                    Import
                                </Button>
                            </Box>
                        </Card>
                    )}
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flex: 1,
                        height: '100%',
                        maxHeight: 'calc(100dvh - 90px - 78px - 32px - 170px)',
                        minHeight:
                            'calc(64px + 72px + 2px + 56px + 56px + 56px + 56px + 56px)',
                    }}
                >
                    <UserManagementTable<IGroupMemberItem>
                        columns={
                            groupManagementData.groupManagementTableData.columns
                        }
                        rows={groupManagementData.groupManagementTableData.rows}
                        pagination={{
                            count: groupManagementData.count,
                            take: groupManagementData.take,
                            skip: groupManagementData.skip,
                            countsRowsByOnePage: [5, 10, 20],
                            setSkip: groupManagementData.setSkip,
                            setTake: groupManagementData.setTake,
                        }}
                        toolbar={<CustomToolbar />}
                        isLoading={groupManagementData.isLoading}
                        notFoundMessage="Not Found"
                        handlePressSort={groupManagementData.handlePresSortIcon}
                        sortType={groupManagementData.sortType}
                        sortOrder={groupManagementData.sortOrder}
                        itemComponent={GroupMemberTableItem}
                    />
                </Box>
            </Box>
        </div>
    )
}

export default GroupManagementPage
