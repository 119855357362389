import {
    Box,
    Button,
    Container,
    Grid,
    Link,
    Paper,
    Typography,
    styled,
} from '@mui/material'
import { useState } from 'react'
import { ArrowBack, ArrowForward, Info } from '@mui/icons-material'

const StyledCard = styled(Box)(() => ({
    height: '230px',
    border: `1px solid rgba(203, 203, 203, 1)`,
    borderRadius: '20px',
    backgroundColor: '#fff',
    cursor: 'pointer',
    '&:hover': {
        borderColor: 'rgba(26, 117, 255, 1)',
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    img: {
        marginBottom: '24px',
    },
}))

const NewOnboardingInstallationStep = ({ setStep }) => {
    const [installFor, setInstallFor] = useState('')

    return (
        <Grid container item sx={{ p: 2, pb: 10, justifyContent: 'center' }}>
            <Container maxWidth="md">
                <Grid
                    item
                    xs={12}
                    sx={{
                        width: '60svw',
                        pb: 5,
                        borderBottom: '1px solid rgba(34, 51, 84, 0.2)',
                    }}
                >
                    <Typography
                        variant="h1"
                        mt={5}
                        display="flex"
                        alignItems="flex-start"
                    >
                        Install the Alpaca Extension
                    </Typography>
                    <Typography
                        variant="subtitle2"
                        sx={{ marginTop: 1.5, fontSize: '20px' }}
                    >
                        Alpaca Notify works by sending notifications to
                        Chromebooks using the Alpaca Notify extension. Before
                        you can start sending out notifications, you will need
                        to deploy the Alpaca Notify extension to your
                        organization's Chromebooks. Let's help you get this set
                        in a few easy steps!
                    </Typography>
                </Grid>

                <Grid item xs={12} mt={4}>
                    <Typography variant="h4" sx={{ mb: 1 }}>
                        Before we begin...
                    </Typography>

                    <Typography variant="body1" fontSize={16} color="black">
                        Will you be installing the extension for a single
                        computer or your organization?
                    </Typography>

                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(2, 1fr)',
                            columnGap: 4,
                            mt: 5,
                        }}
                    >
                        <StyledCard
                            onClick={() => setInstallFor('fullOrganization')}
                            sx={{
                                borderColor:
                                    installFor === 'fullOrganization'
                                        ? 'rgba(26, 117, 255, 1)'
                                        : 'rgba(203, 203, 203, 1)',
                            }}
                        >
                            <img
                                src="/static/images/other/full-organization.png"
                                alt="Single computer"
                                width={77}
                                height={77}
                            />

                            <Typography variant="h4">
                                Full Organization
                            </Typography>
                        </StyledCard>

                        <StyledCard
                            onClick={() => setInstallFor('singleComputer')}
                            sx={{
                                borderColor:
                                    installFor === 'singleComputer'
                                        ? 'rgba(26, 117, 255, 1)'
                                        : 'rgba(203, 203, 203, 1)',
                            }}
                        >
                            <img
                                src="/static/images/other/single-computer.png"
                                alt="Single computer"
                                width={77}
                                height={77}
                            />

                            <Typography variant="h4">
                                Single Computer
                            </Typography>
                        </StyledCard>
                    </Box>

                    {!!installFor && (
                        <Paper sx={{ p: 2, borderRadius: 2, mt: 4 }}>
                            <Box>
                                <Info
                                    sx={{
                                        width: '32px',
                                        height: '32px',
                                        path: {
                                            fill: '#FFA31A',
                                        },
                                    }}
                                />
                            </Box>

                            <Typography
                                sx={{
                                    fontWeight: 500,
                                    fontSize: '20px',
                                    lineHeight: '28px',
                                    mt: 1.5,
                                }}
                            >
                                {installFor === 'fullOrganization' ? (
                                    <>
                                        To install the Alpaca Notify extension
                                        for your entire organization we will
                                        need to use the Google Admin Console.
                                        Please make sure you have the correct
                                        access and{' '}
                                        <Link
                                            href="/"
                                            target="_blank"
                                            sx={{ fontWeight: 700 }}
                                        >
                                            CLICK HERE
                                        </Link>{' '}
                                        to get started.
                                    </>
                                ) : (
                                    <>
                                        You can install the Alpaca Notify
                                        extension on your device or on devices
                                        you are testing by downloading and
                                        installing it form the Chrome Web Store{' '}
                                        <Link
                                            href="/"
                                            target="_blank"
                                            sx={{ fontWeight: 700 }}
                                        >
                                            HERE
                                        </Link>
                                    </>
                                )}
                            </Typography>
                        </Paper>
                    )}
                </Grid>
            </Container>

            <Grid
                item
                justifySelf="flex-end"
                sx={{ position: 'fixed', bottom: 0, zIndex: 99 }}
                xs={12}
            >
                <Paper
                    variant="outlined"
                    sx={{
                        height: '64px',
                        width: '100svw',
                        paddingTop: '12px',
                        paddingBottom: '12px',
                        borderRadius: 0,
                    }}
                >
                    <Container
                        maxWidth="md"
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Button
                            sx={{ width: '130px' }}
                            variant="contained"
                            startIcon={<ArrowBack />}
                            onClick={() => setStep(prev => prev - 1)}
                        >
                            Back
                        </Button>

                        <Button
                            sx={{ width: '271px' }}
                            disabled={!installFor}
                            variant="contained"
                            endIcon={<ArrowForward />}
                            onClick={() => setStep(prev => prev + 1)}
                        >
                            Go to Setup Instructions
                        </Button>
                    </Container>
                </Paper>
            </Grid>
        </Grid>
    )
}

export default NewOnboardingInstallationStep
