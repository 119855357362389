import { Box } from '@mui/material'
import Lottie from 'react-lottie-player'
import LottieJson from './animation.js'
import { useRoutes } from 'react-router-dom'
import router from 'src/router';
import useAuth from 'src/hooks/useAuth';

function AppInit() {
  const content = useRoutes(router)
  const auth = useAuth()

  return (
    <Box sx={{
      width: '100%',
      height: '100%',
      display: 'flex'
    }}>
      {auth.isInitialized ? (
        content
      ) : (
        <Box
          sx={{
            position: 'fixed',
            left: 0,
            top: 0,
            bottom: 0,
            right: 0,
            width: '100%',
            height: '100%',
          }}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Lottie
            loop
            animationData={LottieJson}
            play
            style={{ width: 150, height: 150 }}
          ></Lottie>
        </Box>
      )}
    </Box>
  )
}

export default AppInit
