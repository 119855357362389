import React from 'react'

const OrgDetails = ({ color }: { color: string }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_302_13718)">
                <path opacity="0.3" d="M5 5V19H19V5H5ZM9 17H7V10H9V17ZM13 17H11V7H13V17ZM17 17H15V13H17V17Z" fill={color} />
                <path d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM19 19H5V5H19V19ZM7 10H9V17H7V10ZM11 7H13V17H11V7ZM15 13H17V17H15V13Z" fill={color} />
            </g>
            <defs>
                <clipPath id="clip0_302_13718">
                    <rect width="24" height="24" fill={color} />
                </clipPath>
            </defs>
        </svg>

    )
}

export default OrgDetails