import { ArrowBack, ArrowForward } from '@mui/icons-material'
import { Button, Container, Grid, Paper, Typography } from '@mui/material'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-mui'
import { forwardRef, useState } from 'react'
import { IMaskInput } from 'react-imask'
import { useUpdateOnboardingInfo } from 'src/api/hooks/useOnboarding.hooks'
import CountryPicker from 'src/components/atoms/CountryPicker/CountryPicker'
import TimeZonePicker from 'src/components/atoms/TimezonePicker/TimezonePicker'
import * as Yup from 'yup'

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void
    name: string
}

const TextMaskCustom = forwardRef<HTMLInputElement, CustomProps>(
    function TextMaskCustom(props, ref) {
        const { onChange, ...other } = props
        return (
            <IMaskInput
                {...other}
                mask="+0 000-000-0000"
                definitions={{
                    '#': /[1-9]/,
                }}
                inputRef={ref}
                onAccept={(value: any) =>
                    onChange({ target: { name: props.name, value } })
                }
                overwrite
                placeholder="+44 20 1234 5678"
            />
        )
    }
)

const ValidationSchema = Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
    address1: Yup.string().required('Required'),
    address2: Yup.string(),
    city: Yup.string().required('Required'),
    state: Yup.string().required('Required'),
    zipCode: Yup.string().required('Required'),
    phoneNumber: Yup.string().required('Required'),
    organizationName: Yup.string().required('Required'),
    country: Yup.string().required('Required'),
    timezone: Yup.string().required('Required'),
})

interface Country {
    name: string
    alpha2Code: string
    timezones: string[]
}

const NewOnboardingContactInfoStep = ({ setStep }) => {
    const [selectedCountry, setSelectedCountry] = useState<Country | null>(null)

    const { mutate: handleUpdateOrgInfo } = useUpdateOnboardingInfo()

    const handleCountryChange = (country: Country | null) => {
        setSelectedCountry(country)
    }

    return (
        <Grid container item sx={{ p: 2, pb: 10, justifyContent: 'center' }}>
            <Formik
                initialValues={{
                    firstName: '',
                    lastName: '',
                    address1: '',
                    address2: '',
                    city: '',
                    state: '',
                    zipCode: '',
                    phoneNumber: '',
                    email: '',
                    organizationName: '',
                    country: '',
                    timezone: '',
                }}
                onSubmit={(values, formikBag) => {
                    handleUpdateOrgInfo({
                        ...values,
                        phoneNumber: values.phoneNumber.replace(/[+ ]/g, ""),
                        organizationCountry: values.country,
                        organizationTimeZone: values.timezone,
                    }, {
                        onSuccess() {
                            setStep(prev => prev + 1)
                        },
                        onError(error: any[]) {
                            error.map(error => {
                                if (error.path === 'organizationCountry') {
                                    return formikBag.setFieldError('country', error.msg)
                                }
                                if (error.path === 'organizationTimeZone') {
                                    return formikBag.setFieldError('timezone', error.msg)
                                }
                                return formikBag.setFieldError(error.path, error.msg)
                            })
                            formikBag.setSubmitting(false)
                        }
                    })
                }}
                validationSchema={ValidationSchema}
                validateOnChange
            >
                {({ values, submitForm, dirty, isValid, setFieldValue }) => {

                    return (
                        <>
                            <Container maxWidth="md">
                                <Form>
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            flexDirection: 'column',
                                            width: '60svw',
                                        }}
                                    >
                                        <Typography
                                            variant="h2"
                                            mt={5}
                                            display="flex"
                                            alignItems="center"
                                        >
                                            Tell us who you are
                                        </Typography>
                                        <Typography
                                            variant="subtitle2"
                                            sx={{ marginTop: 1 }}
                                        >
                                            Let’s gather some information to
                                            help setup your account.
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Paper
                                            sx={{
                                                p: 2,
                                                mt: 4,
                                                borderBottomLeftRadius: 0,
                                                borderBottomRightRadius: 0,
                                            }}
                                        >
                                            <Typography
                                                variant="h5"
                                                sx={{ mb: 1 }}
                                            >
                                                What’s your name?
                                            </Typography>

                                            <Grid
                                                xs={12}
                                                container
                                                spacing={2}
                                                ml={0}
                                            >
                                                <Grid
                                                    xs={6}
                                                    item
                                                    pl={'0px !important'}
                                                >
                                                    <Field
                                                        fullWidth
                                                        component={TextField}
                                                        id="firstName"
                                                        name="firstName"
                                                        label="First Name"
                                                    />
                                                </Grid>

                                                <Grid xs={6} item>
                                                    <Field
                                                        fullWidth
                                                        component={TextField}
                                                        id="lastName"
                                                        name="lastName"
                                                        label="Last Name"
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Typography
                                                variant="h5"
                                                sx={{ mb: 1, mt: 4 }}
                                            >
                                                How can we contact you?
                                            </Typography>

                                            <Grid
                                                xs={12}
                                                container
                                                spacing={2}
                                                ml={0}
                                            >
                                                <Grid
                                                    xs={6}
                                                    item
                                                    pl={'0px !important'}
                                                >
                                                    <Field
                                                        fullWidth
                                                        component={TextField}
                                                        id="address1"
                                                        name="address1"
                                                        label="Address 1"
                                                    />
                                                </Grid>

                                                <Grid xs={6} item>
                                                    <Field
                                                        fullWidth
                                                        component={TextField}
                                                        id="address2"
                                                        name="address2"
                                                        label="Address 2"
                                                    />
                                                </Grid>

                                                <Grid
                                                    xs={6}
                                                    item
                                                    pl={'0px !important'}
                                                >
                                                    <Field
                                                        fullWidth
                                                        component={TextField}
                                                        id="city"
                                                        name="city"
                                                        label="City"
                                                    />
                                                </Grid>

                                                <Grid
                                                    xs={6}
                                                    item
                                                    container
                                                    spacing={2}
                                                >
                                                    <Grid xs={6} item>
                                                        <Field
                                                            fullWidth
                                                            component={
                                                                TextField
                                                            }
                                                            id="state"
                                                            name="state"
                                                            label="State"
                                                        />
                                                    </Grid>

                                                    <Grid xs={6} item>
                                                        <Field
                                                            fullWidth
                                                            component={
                                                                TextField
                                                            }
                                                            id="zipCode"
                                                            name="zipCode"
                                                            label="Zip Code"
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Grid
                                                    xs={6}
                                                    item
                                                    pl={'0px !important'}
                                                >
                                                    <Field
                                                        fullWidth
                                                        component={TextField}
                                                        id="phoneNumber"
                                                        name="phoneNumber"
                                                        label="Phone Number"
                                                        InputProps={{
                                                            inputComponent:
                                                                TextMaskCustom as any,
                                                        }}
                                                    />
                                                </Grid>

                                                <Grid xs={6} item>
                                                    <Field
                                                        fullWidth
                                                        component={TextField}
                                                        id="email"
                                                        name="email"
                                                        label="Email Address"
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Typography
                                                variant="h5"
                                                sx={{ mb: 1, mt: 4 }}
                                            >
                                                What is the name of your
                                                organization??
                                            </Typography>

                                            <Grid>
                                                <Field
                                                    fullWidth
                                                    component={TextField}
                                                    id="organizationName"
                                                    name="organizationName"
                                                    placeholder="Organization name"
                                                />
                                            </Grid>

                                            <Typography
                                                variant="h5"
                                                sx={{ mb: 1, mt: 4 }}
                                            >
                                                What timezone would you like to
                                                use?
                                            </Typography>

                                            <Grid
                                                xs={12}
                                                container
                                                spacing={2}
                                                ml={0}
                                            >
                                                <Grid
                                                    xs={6}
                                                    item
                                                    pl={'0px !important'}
                                                >
                                                    <CountryPicker
                                                        setFieldValue={setFieldValue}
                                                        onCountryChange={
                                                            handleCountryChange
                                                        }
                                                        sx={{ width: '100%' }}
                                                    />
                                                </Grid>

                                                <Grid xs={6} item>
                                                    <TimeZonePicker
                                                        setFieldValue={setFieldValue}
                                                        selectedCountry={
                                                            selectedCountry
                                                        }
                                                        sx={{ width: '100%' }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                </Form>
                            </Container>
                            <Grid
                                item
                                justifySelf="flex-end"
                                sx={{ position: 'fixed', bottom: 0, zIndex: 99 }}
                                xs={12}
                            >
                                <Paper
                                    variant="outlined"
                                    sx={{
                                        height: '64px',
                                        width: '100svw',
                                        paddingTop: '12px',
                                        paddingBottom: '12px',
                                        borderRadius: 0,
                                    }}
                                >
                                    <Container
                                        maxWidth="md"
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <Button
                                            sx={{ width: '130px' }}
                                            variant="contained"
                                            startIcon={<ArrowBack />}
                                            onClick={() => setStep(prev => prev - 1)}
                                        >
                                            Back
                                        </Button>

                                        <Button
                                            sx={{ width: '160px' }}
                                            disabled={!(dirty && isValid)}
                                            variant="contained"
                                            endIcon={<ArrowForward />}
                                            onClick={() => {
                                                submitForm()
                                            }}
                                        >
                                            Continue
                                        </Button>
                                    </Container>
                                </Paper>
                            </Grid>
                        </>
                    )
                }}
            </Formik>
        </Grid>
    )
}

export default NewOnboardingContactInfoStep
