import { ArrowBack, ArrowForward } from '@mui/icons-material'
import {
    Box,
    Button,
    Container,
    Grid,
    Link,
    Paper,
    Typography,
    styled,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'

const StyledCard = styled(Paper)(() => ({
    padding: '56px 22px 40px 22px',
    height: '290px',
    borderRadius: '20px',
    backgroundColor: '#fff',
    cursor: 'pointer',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    img: {
        marginBottom: '24px',
    },
}))

const options = [
    {
        title: 'Want to learn more?',
        img: '/static/images/other/want-to-learn-more.png',
        link: {
            href: '/',
            text: 'Knowledge Page',
        },
    },
    {
        title: 'Still need help?',
        img: '/static/images/other/need-help.png',
        link: {
            href: '/',
            text: 'Support Page',
        },
    },
    {
        title: 'Schedule a time to speak with an us.',
        img: '/static/images/other/schedule-time.png',
        link: {
            href: '/',
            text: 'Contact Us',
        },
    },
]

const NewOnboardingStepFive = ({ setStep }) => {

    const navigate = useNavigate();

    const handleCompleteOnboarding = () => {
        navigate('/calendar', { replace: true });
    }

    return (
        <Grid container item sx={{ p: 2, pb: 10, justifyContent: 'center' }}>
            <Container maxWidth="md">
                <Grid
                    item
                    xs={12}
                    sx={{
                        width: '60svw',
                        pb: 5,
                        borderBottom: '1px solid rgba(34, 51, 84, 0.2)',
                    }}
                >
                    <Typography
                        variant="h1"
                        mt={5}
                        display="flex"
                        alignItems="flex-start"
                    >
                        Aaand you're done!
                    </Typography>
                    <Typography
                        variant="subtitle2"
                        sx={{ marginTop: 1.5, fontSize: '20px' }}
                    >
                        We're excited for you to use Alpaca Notify and join our
                        growing family. If you need any help or have questions
                        please use the links below to learn or schedule a time
                        to speak with an Alpaca Notify expert.
                    </Typography>
                </Grid>

                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(3, 1fr)',
                        columnGap: 3,
                        mt: 7,
                    }}
                >
                    {options.map(({ img, title, link }) => (
                        <StyledCard key={title}>
                            <img src={img} alt={title} width={77} height={77} />

                            <Box
                                sx={{
                                    flex: '1 1 0',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: '18px',
                                        lineHeight: '22px',
                                        textAlign: 'center',
                                        fontWeight: 600,
                                    }}
                                >
                                    {title}
                                </Typography>

                                <Link
                                    href={link.href}
                                    target="_blank"
                                    sx={{
                                        fontSize: '16px',
                                        fontWeight: 600,
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    {link.text}{' '}
                                    <ArrowForward sx={{ ml: 0.75 }} />
                                </Link>
                            </Box>
                        </StyledCard>
                    ))}
                </Box>
            </Container>

            <Grid
                item
                justifySelf="flex-end"
                sx={{ position: 'fixed', bottom: 0, zIndex: 99 }}
                xs={12}
            >
                <Paper
                    variant="outlined"
                    sx={{
                        height: '64px',
                        width: '100svw',
                        paddingTop: '12px',
                        paddingBottom: '12px',
                        borderRadius: 0,
                    }}
                >
                    <Container
                        maxWidth="md"
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Button
                            sx={{ width: '130px' }}
                            variant="contained"
                            startIcon={<ArrowBack />}
                            onClick={() => setStep(prev => prev - 1)}
                        >
                            Back
                        </Button>

                        <Button
                            variant="contained"
                            endIcon={<ArrowForward />}
                            sx={{ px: 4 }}
                            onClick={handleCompleteOnboarding}
                        >
                            Off to the console!
                        </Button>
                    </Container>
                </Paper>
            </Grid>
        </Grid>
    )
}

export default NewOnboardingStepFive
