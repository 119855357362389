import { FC, ReactNode, useContext, useState } from 'react'
import {
  Box,
  Modal,
  alpha,
  lighten,
  useTheme,
  styled,
  Typography,
  Button,
} from '@mui/material'
import { Outlet } from 'react-router-dom'
import ThemeSettings from 'src/components/ThemeSettings'

import Sidebar from './Sidebar'
import Header from './Header'
import AuthContext from 'src/contexts/JWTAuthContext'

interface ExtendedSidebarLayoutProps {
  children?: ReactNode
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 481,
  bgcolor: '#FFFFFF',
  borderRadius: '16px',
  boxShadow: 24,
  p: 3,
  outline: 'none',
}

const ModalTitle = styled(Typography)(({ theme }) => ({
  fontSize: '18px',
  fontWeight: 600,
  lineHeight: '21.78px',
  textAlign: 'left',
  color: '#000000',
}))

const AdditionalInfoBox = styled(Box)(() => ({
  padding: '12px',
  borderRadius: '8px',
  backgroundColor: '#F7F7F9',
  display: 'flex',
  gap: '8px',
  marginTop: '32px',
  marginBottom: '40px',
}))

const AdditionalInfoText = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  textAlign: 'left',
  color: '#05152D',
}))

const ExtendedSidebarLayout: FC<ExtendedSidebarLayoutProps> = () => {
  const theme = useTheme()

  const { user } = useContext(AuthContext)

  const daysLeft: number = user.licensesTimeLeft?.days || 1
  const isTrial = user.orgStatus === "Trial"

  const [isModalOpen, setModalOpen] = useState<boolean>(
    daysLeft <= 3
  )

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  return (
    <>
      <Box
        id="main"
        sx={{
          flex: 1,
          height: '100%',

          '.MuiPageTitle-wrapper': {
            background:
              theme.palette.mode === 'dark'
                ? theme.colors.alpha.trueWhite[5]
                : theme.colors.alpha.white[50],
            marginBottom: `${theme.spacing(3)}`,
            boxShadow:
              theme.palette.mode === 'dark'
                ? `0 1px 0 ${alpha(
                  lighten(theme.colors.primary.main, 0.7),
                  0.15
                )}, 0px 2px 4px -3px rgba(0, 0, 0, 0.2), 0px 5px 12px -4px rgba(0, 0, 0, .1)`
                : `0px 2px 4px -3px ${alpha(
                  theme.colors.alpha.black[100],
                  0.1
                )}, 0px 5px 12px -4px ${alpha(
                  theme.colors.alpha.black[100],
                  0.05
                )}`,
          },
        }}
      >
        <Header />
        <Sidebar />
        <Box
          sx={{
            position: 'relative',
            zIndex: 5,
            display: 'block',
            flex: 1,
            pt: `${theme.header.height}`,
            [theme.breakpoints.up('lg')]: {
              ml: `${theme.sidebar.width}`,
            },
          }}
        >
          <Box display="block">
            <Outlet />
          </Box>
        </Box>
      </Box>

      <Modal open={isModalOpen}>
        <Box sx={style}>
          {daysLeft !== 0 && <Button
            sx={{
              bgcolor: '#FD3535',
              borderRadius: '50%',
              height: '56px',
              width: '56px',
              minWidth: '56px',
              position: 'absolute',
              top: '-28px',
              right: '-28px',
              '&:hover': {
                backgroundColor: '#FD3535'
              }
            }}
            onClick={handleCloseModal}
          >
            <img
              src="/static/images/icons/cross-icon.svg"
              alt="close"
              style={{ width: '18.67px', height: '18.67px' }}
            />
          </Button>}
          <ModalTitle id="modal-modal-title">
            {daysLeft <= 3 && daysLeft !== 0 &&
              'The expiration date coming up soon'}
            {daysLeft === 0 && !isTrial &&
              'Your license expiration date has reached'}
            {daysLeft === 0 && isTrial &&
              '30-day trial period has ended'}
          </ModalTitle>
          <AdditionalInfoBox>
            <img
              src="/static/images/icons/i-icon.svg"
              alt="close"
              style={{ marginBottom: 'auto' }}
            />
            <AdditionalInfoText>
              {daysLeft <= 3 && daysLeft !== 0 &&
                'Please note that the Expiration date is coming soon. To have access to send notifications, please contact us for a personalized quote or more information.'}
              {daysLeft === 0 &&
                'To regain access to send notifications again, please contact us for a personalized quote or more information.'}
            </AdditionalInfoText>
          </AdditionalInfoBox>
          <Button
            fullWidth
            variant="contained"
            disableRipple
            component={'a'}
            sx={{ height: '56px' }}
            href={'https://support.alpacanotify.com/en/'}
            rel={"noopener noreferrer"}
          >
            Contact Us
          </Button>
        </Box>
      </Modal>
    </>
  )
}

export default ExtendedSidebarLayout
