import axiosInt from "src/utils/axios"
import { UpdateOrgInfoBody } from "../types/orgSettings.types"
import { MembersRequestParams } from "../types/userManagement.types"
import { AxiosResponse } from "axios"

export const getOrganizationInfo = (orgId: string) =>
    axiosInt.get(`/organizations/find-one/${orgId}`)

export const updateOrganizationInfo = (orgId: string, data: UpdateOrgInfoBody) =>
    axiosInt.patch(`/organizations/update/${orgId}`, { ...data }).then(res => res.data)

export const getUserPermissionList = async (params: MembersRequestParams) =>
    axiosInt.get('/users', { params }).then((res) => res.data)

export const updateMemberRole = async (groupId: string, data: any) =>
    axiosInt.post(`/organizations/changeRole/${groupId}`, { ...data })

export const userUpdateAvatar = async (groupId: string, data: FormData) =>
    axiosInt.post(`/organizations/uploadLogo/${groupId}`, data).then((res: AxiosResponse<{ url: string }, any>) => res.data)