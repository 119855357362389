import { ArrowBack, ArrowForward, Check } from '@mui/icons-material'
import {
    Box,
    Button,
    Checkbox,
    Container,
    Grid,
    Link,
    Paper,
    Typography,
    styled,
} from '@mui/material'
import React, { useState } from 'react'
import { useAcceptAgreements } from 'src/api/hooks/useOnboarding.hooks'

const StyledCard = styled(Paper)(() => ({
    padding: '56px 22px 32px 22px',

    borderRadius: '20px',
    backgroundColor: '#fff',
    cursor: 'pointer',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    img: {
        marginBottom: '24px',
    },
}))

const options = [
    {
        text: 'TOS (Terms of Service)',
        img: '/static/images/other/tos.png',
        link: '/',
        field: 'termsOfServices',
    },
    {
        text: 'Privacy Policy',
        img: '/static/images/other/privacy-police.png',
        link: '/',
        field: 'privacyPolicy',
    },
    {
        text: 'EUL (End User License)',
        img: '/static/images/other/eul.png',
        link: '/',
        field: 'endUserLicense',
    },
]

const NewOnboardingAgreementStep = ({ setStep }) => {
    const [agreements, setAgreements] = useState({
        termsOfServices: false,
        privacyPolicy: false,
        endUserLicense: false,
    })

    const { mutate: acceptAgreement } = useAcceptAgreements()

    const acceptAll = () => {
        const test = Object.keys(agreements).reduce((acc, curr) => {
            acc[curr] = true
            return acc
        }, {} as typeof agreements)

        setAgreements(test)
    }

    const areAgreementsValid = () => {
        return Object.values(agreements).every(agreement => agreement === true)
    }

    const handleAcceptAgreement = () => {
        acceptAgreement(void 0, {
            onSuccess() {
                setStep(prev => prev + 1)
            },
        })

    }

    return (
        <Grid container item sx={{ p: 2, pb: 10, justifyContent: 'center' }}>
            <Container maxWidth="md">
                <Grid
                    item
                    xs={12}
                    sx={{
                        width: '60svw',
                        pb: 5,
                        borderBottom: '1px solid rgba(34, 51, 84, 0.2)',
                    }}
                >
                    <Typography
                        variant="h1"
                        mt={5}
                        display="flex"
                        justifyContent="center"
                    >
                        <img
                            src="/static/images/other/document-emoji.png"
                            alt="smile"
                            width={40}
                            height={40}
                            style={{ marginRight: '12px' }}
                        />
                        First things first
                    </Typography>
                    <Typography
                        variant="subtitle2"
                        textAlign="center"
                        sx={{ marginTop: 1.5, fontSize: '20px' }}
                    >
                        We have a few legal things to take care of. Please take
                        a moment to read and agree to the following.
                    </Typography>
                </Grid>

                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(3, 1fr)',
                        columnGap: 3,
                        mt: 7,
                    }}
                >
                    {options.map(({ img, text, link, field }) => (
                        <StyledCard key={text}>
                            <img src={img} alt={text} width={77} height={77} />

                            <Typography
                                sx={{
                                    fontSize: '18px',
                                    lineHeight: '44px',
                                }}
                            >
                                I have read and agree to the
                            </Typography>

                            <Link
                                href={link}
                                target="_blank"
                                sx={{
                                    fontSize: '16px',
                                    fontWeight: 600,
                                    mb: '21px',
                                }}
                            >
                                {text}
                            </Link>

                            <Checkbox
                                sx={{
                                    p: 0,
                                    '.MuiSvgIcon-root': {
                                        width: '32px',
                                        height: '32px',
                                    },
                                }}
                                checked={(() => {
                                    console.log(agreements[field])
                                    return agreements[field]
                                })()}
                                onChange={e =>
                                    setAgreements(prev => ({
                                        ...prev,
                                        [field]: e.target.checked,
                                    }))
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </StyledCard>
                    ))}
                </Box>

                <Box sx={{ textAlign: 'center', mt: 7 }}>
                    <Button
                        startIcon={<Check />}
                        variant="outlined"
                        sx={{ backgroundColor: '#fff', px: 3 }}
                        onClick={acceptAll}
                    >
                        Accept All
                    </Button>
                </Box>
            </Container>

            <Grid
                item
                justifySelf="flex-end"
                sx={{ position: 'fixed', bottom: 0, zIndex: 99 }}
                xs={12}
            >
                <Paper
                    variant="outlined"
                    sx={{
                        height: '64px',
                        width: '100svw',
                        paddingTop: '12px',
                        paddingBottom: '12px',
                        borderRadius: 0,
                    }}
                >
                    <Container
                        maxWidth="md"
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Button
                            sx={{ width: '130px' }}
                            variant="contained"
                            startIcon={<ArrowBack />}
                            onClick={() => setStep(prev => prev - 1)}
                        >
                            Back
                        </Button>

                        <Button
                            sx={{ width: '160px' }}
                            variant="contained"
                            disabled={!areAgreementsValid()}
                            endIcon={<ArrowForward />}
                            onClick={handleAcceptAgreement}
                        >
                            Continue
                        </Button>
                    </Container>
                </Paper>
            </Grid>
        </Grid>
    )
}

export default NewOnboardingAgreementStep
