import axiosInt from "src/utils/axios"
import { GroupRequestParams, MembersRequestParams } from "../types/userManagement.types"


export const getGroupList = async (params: GroupRequestParams) =>
    axiosInt.get('/groups', { params }).then((res) => res.data)

export const getUserList = async (params: MembersRequestParams) =>
    axiosInt.get('/org-members', { params }).then((res) => res.data)

export const getUserFromGroup = async (groupId: string, params: MembersRequestParams) =>
    axiosInt.get(`/groups/${groupId}/members`, { params }).then((res) => res.data)

export const getGroupInfoById = async (groupId: string) =>
    axiosInt.get(`/groups/${groupId}`).then((res) => res.data)

export const syncDataWithGoogle = async () =>
    axiosInt.get('/oauth/importGoogleOrganization').then(res => res.data)

export const getOrgInfo = async (orgId: string) =>
    axiosInt.get(`/organizations/info/${orgId}`).then(res => res.data)


export const excludeGroup = async (groupIds: string[]) =>
    axiosInt.patch('/groups/exclude', { groupIds: [...groupIds] }).then(res => res.data)

export const includeGroup = async (groupIds: string[]) =>
    axiosInt.patch('/groups/include', { groupIds: [...groupIds] }).then(res => res.data)

    
export const excludeOrgMember = async (memberIds: string[]) =>
    axiosInt.patch('/org-members/exclude', { memberIds: [...memberIds] }).then(res => res.data)

export const includeOrgMember = async (memberIds: string[]) =>
    axiosInt.patch('/org-members/include', { memberIds: [...memberIds] }).then(res => res.data)