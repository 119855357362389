import React from 'react'

const BillingIcon = ({ color }: { color: string }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_302_13724)">
                <path opacity="0.3" d="M12 4C7.59 4 4 7.59 4 12C4 16.41 7.59 20 12 20C16.41 20 20 16.41 20 12C20 7.59 16.41 4 12 4ZM13.23 17.33V19H10.9V17.31C9.4 17 8.13 16.03 8.04 14.34H9.75C9.84 15.26 10.47 15.98 12.07 15.98C13.78 15.98 14.17 15.12 14.17 14.59C14.17 13.86 13.78 13.18 11.83 12.72C9.66 12.19 8.17 11.3 8.17 9.51C8.17 8 9.39 7.03 10.89 6.7V5H13.23V6.71C14.86 7.1 15.67 8.34 15.72 9.68H14.01C13.97 8.71 13.45 8.04 12.07 8.04C10.76 8.04 9.97 8.63 9.97 9.47C9.97 10.2 10.54 10.69 12.31 11.14C14.08 11.6 15.97 12.36 15.97 14.56C15.96 16.16 14.76 17.04 13.23 17.33Z" fill={color} />
                <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM12.31 11.14C10.54 10.69 9.97 10.2 9.97 9.47C9.97 8.63 10.76 8.04 12.07 8.04C13.45 8.04 13.97 8.7 14.01 9.68H15.72C15.67 8.34 14.85 7.11 13.23 6.71V5H10.9V6.69C9.39 7.01 8.18 7.99 8.18 9.5C8.18 11.29 9.67 12.19 11.84 12.71C13.79 13.17 14.18 13.86 14.18 14.58C14.18 15.11 13.79 15.97 12.08 15.97C10.48 15.97 9.85 15.25 9.76 14.33H8.04C8.14 16.03 9.4 16.99 10.9 17.3V19H13.24V17.33C14.76 17.04 15.96 16.17 15.97 14.56C15.96 12.36 14.07 11.6 12.31 11.14Z" fill={color} />
            </g>
            <defs>
                <clipPath id="clip0_302_13724">
                    <rect width="24" height="24" fill={color} />
                </clipPath>
            </defs>
        </svg>

    )
}

export default BillingIcon